import Login from "./components/auth/Login";
import SignUp from "./components/auth/Signup";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import InsuranceInfo from "./components/insurance/InsuranceInfo";
import MedicationSearch from "./components/medications/MedicationSearch";
import Savings from "./components/savings";
import MedicationEdit from "./components/medications/MedicationEdit";
import CoupanCard from "./components/coupan/CoupanCard";
import IAmHere from "./components/coupan/IamHere";
import CoupanSend from "./components/coupan/CouponSent";
import NumberVerification from "./components/numberVerification/NumberVerification";
import CopayProgram from "./components/copayProgram/CopayProgram";
import FindPharmacy from "./components/findPharmacy/FindPharmacy";
import SearchPharmacy from "./components/searchPharmacy";
import ForgotTempPassword from "./components/auth/forgotTempPassword";
import MyAccount from "./components/myAccount/MyAccount";
import { successToast, errorToast } from "./components/toast/toast";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setResetPwdModal } from "./reducers/medication";
import { resetPassword } from "./reducers/resetPassword";
import { StringToHTML } from "./utilities/StringToHtml";
import { setAnimatedLoading } from "./reducers/global";
import LoadingComponent from "./components/LoadingComponent";
import { useGetUser } from "./utilities/useGetUser";
import { useNavigate } from "react-router-dom";



const routes = ["/insurance", "/CopayProgram", "/FindPharmacy", "/medications", "/edit-medications", "/coupon", "/iamHere", "/couponSend", "/searchPharmacy", "/myAccount", "/savings"];

export function RoutesWithAnimation({ auth, setAuth, ownPosition, setOwnPosition, setIp }) {
  const userDetail = useGetUser();
  // var userDetail = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const showResetPwdModal = useSelector((state) => state.medication.isOpenResetPasswordModal);

  const closeChangePasswordModal = () => {
    dispatch(setResetPwdModal(false));
    document.body.style.overflow = 'auto';
  }

  useEffect(() => {
    if (showResetPwdModal) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
    } else {
      document.body.style.overflow = 'auto'; // Enable scrolling when modal is closed
    }
  }, [showResetPwdModal]);

  const submitResetPassword = (submitResetPasswordData, resetForm) => {
    if(loading) return;
    if (submitResetPasswordData?.password) {
      let resetPwd = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword && !submitResetPasswordData?.oldPassword? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };
      if (resetPwd?.password) {
        setLoading(true);
        dispatch(resetPassword(resetPwd))
          .unwrap()
          .then((response) => {
            setLoading(false);
            if (response?.statusCode >= 200 && response?.statusCode <= 299) {
              let extendedUser = JSON.parse(localStorage.getItem("user"));
              localStorage.setItem("user", JSON.stringify({
                ...extendedUser,
                token: response.data.token,
                refreshToken: response.data.refreshToken
              }));
              closeChangePasswordModal()
              successToast(response?.message);
              if(resetForm) resetForm();
            } else {
              if (response?.statusCode === 401) {
                errorToast("Old password is invalid.");
                if(resetForm) resetForm();
              } else {
                errorToast(<StringToHTML htmlString={response?.message} />);
                if(resetForm) resetForm();
              }
            }
          })
          .catch((e) => {
            setLoading(false);
            if(resetForm) resetForm();
          });
      }
    }
  }

  const location = useLocation();

  console.log({ pathname: location.pathname });

  useEffect(() => {
    if (!userDetail && routes.includes(location.pathname)) {
      navigate("/")
    }
  }, [location.pathname, userDetail])

  return (
    <>
      {loading && (
        <LoadingComponent />
      )}
      <>
        <Routes key={location.pathname} location={location}>
          <Route exact path="*" element={<Login />} />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/signUp" element={<SignUp />} />
          <Route exact path="/otpVerify" element={<NumberVerification setIp={setIp} />} />
          <Route exact path="/insurance" element={<InsuranceInfo />} />
          <Route exact path="/CopayProgram" element={<CopayProgram />} />
          <Route exact path="/FindPharmacy" element={<FindPharmacy />} />
          <Route exact path="/medications" element={<MedicationSearch ownPosition={ownPosition} setOwnPosition={setOwnPosition} setIp={setIp} />} />
          <Route exact path="/edit-medications" element={<MedicationEdit />} />
          <Route exact path="/coupon" element={<CoupanCard ownPosition={ownPosition} />} />
          <Route exact path="/iamHere" element={<IAmHere />} />
          <Route exact path="/couponSend" element={<CoupanSend />} />
          <Route exact path="/searchPharmacy" element={<SearchPharmacy ownPosition={ownPosition} />} />
          <Route exact path="/myAccount" element={<MyAccount />} />
          <Route exact path="/savings" element={<Savings />} />
        </Routes>

        {showResetPwdModal && <ForgotTempPassword
          show={showResetPwdModal}
          close={() => closeChangePasswordModal()}
          setSubmitResetPasswordData={submitResetPassword}
          isOldPassword={true}
        />}
      </>
    </>
  );
}