import careCardInfo from "../reducers/careCardInfo";

export const getCardDetail = (part, med, rxSenseCard, hippoCardInfo, connectiveCards) => {
    let connectCard = connectiveCards?.filter((crd) => crd?.ndc == med?.selectedDrug?.ndc);
    if (part == "BIN") {
        if (med?.selectedDrug?.connectiveRxOffer?.hasCard == 1) return connectCard?.[0]?.bin
        else if (med.hasInsurance) return med?.InsuranceInfo?.bin;
        else if (med.rxSense) return rxSenseCard?.bin;
        else if(med?.isCareCard) return med?.careCardInfo?.bin;
        else if(med?.isRxLess) return med?.careCardInfo?.bin;
        else return hippoCardInfo.bin;
    }
    else if (part == "PCN") {
        if (med?.selectedDrug?.connectiveRxOffer?.hasCard == 1) return connectCard?.[0]?.pcn
        else if (med.hasInsurance) return med?.InsuranceInfo?.pcn;
        else if (med.rxSense) return rxSenseCard?.pcn;
        else if(med?.isCareCard) return med?.careCardInfo?.pcn;
        else if(med?.isRxLess) return med?.careCardInfo?.pcn;
        else return hippoCardInfo.pcn;
    }
    else if (part == "MEMBER_ID") {
        if (med?.selectedDrug?.connectiveRxOffer?.hasCard == 1) return connectCard?.[0]?.memberId
        else if (med.hasInsurance) return med?.InsuranceInfo.ID;
        else if (med.rxSense) return rxSenseCard?.memberId;
        else if(med?.isCareCard) return med?.careCardInfo?.memberId;
        else if(med?.isRxLess) return med?.careCardInfo?.memberId;
        else return hippoCardInfo.member_id;
    }
    else if (part == "GROUP_ID") {
        if (med?.selectedDrug?.connectiveRxOffer?.hasCard == 1) return connectCard?.[0]?.groupId?.split(" ")?.[0]
        else if (med.hasInsurance) return med?.InsuranceInfo.Group;
        else if (med.rxSense) return rxSenseCard?.groupId;
        else if(med?.isCareCard) return med?.careCardInfo?.groupId;
        else if(med?.isRxLess) return med?.careCardInfo?.groupId;
        else return hippoCardInfo.group_id;
    }
}

export const getCard = (med, rxSenseCard, hippoCardInfo, connectiveCards, careCardInfo = {}, rxLessCard = {}) => {
    let connectCard = connectiveCards?.filter((crd) => crd?.ndc == med?.selectedDrug?.ndc);
    if (med?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
        return {
            pcn: connectCard?.[0]?.pcn,
            bin: connectCard?.[0]?.bin,
            memberId: connectCard?.[0]?.memberId,
            groupId: connectCard?.[0]?.groupId?.split(" ")?.[0]
        }
    }
    else {
        return {
            pcn: med?.rxSense ? rxSenseCard?.pcn : med?.hasInsurance ? med?.InsuranceInfo.pcn : med?.isCareCard ? med?.careCardInfo?.pcn : med?.isRxLess ? med?.careCardInfo?.pcn : hippoCardInfo.pcn,
            bin: med?.rxSense ? rxSenseCard?.bin : med?.hasInsurance ? med?.InsuranceInfo.bin : med?.isCareCard ? med?.careCardInfo?.bin : med?.isRxLess ? med?.careCardInfo?.bin : hippoCardInfo.bin,
            memberId: med?.rxSense ? rxSenseCard?.memberId : med?.hasInsurance ? med?.InsuranceInfo.ID : med?.isCareCard ? med?.careCardInfo?.memberId : med?.isRxLess ? med?.careCardInfo?.memberId : hippoCardInfo.member_id,
            groupId: med?.rxSense ? rxSenseCard?.groupId : med?.hasInsurance ? med?.InsuranceInfo.Group : med?.isCareCard ? med?.careCardInfo?.groupId :med?.isRxLess ? med?.careCardInfo?.groupId : hippoCardInfo.group_id,
            hasInsurance: med?.hasInsurance,
            memberInfoSeqNo: med?.rxSense ? rxSenseCard?.memberInfoSeqNo : hippoCardInfo?.memberInfoSeqNo,
            careCardInfoSeqNo: careCardInfo ? careCardInfo?.careCardInfoSeqNo : ''
        }
    }
}

export const getBestPrice = (med, rxSenseCardInfo, memberInfo) => {

    if (med?.description == "with Single Care" && rxSenseCardInfo?.bin == "") {
      return med?.hippoPrice?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )
    }

    else if (med?.description == "with discount card" && memberInfo?.bin == "") {
      return med?.rxSensePrice?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )
    }
    else {
      return med?.bestPrice?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )
    }
  }