import logo from "../assets/images/logo.png"; // for Rxlink
import upennLogo from "../assets/images/PennMedicine.png"; // for PennMedicine
import papajohnsLogo from "../assets/images/papajones-logo.png"; // for papaJohns
import tripmentLogo from "../assets/images/tripment.png"; // for tripment]
import houstonLogo from "../assets/images/houston-health-logo.png"; // for houston-health
import lcmchealthlogo from "../assets/images/LCMC_Health_Logo.png"; // for lcmc
import chnwLogo from "../assets/images/community_health_logo.png";// for community health network
import nclogo from "../assets/images/Norton_clark.png";
import dmhlogo from "../assets/images/decaturmorgan.png";
import dosespotlogo from "../assets/images/dosespot.png";
import mhslogo from "../assets/images/mhs-logo.png";
import prismalogo from "../assets/images/prisma.png";
import mdsxlogo from "../assets/images/mdsx-logo.png";
import uiHealth from "../assets/images/Health_primary_logo.png";


export function getDomainName() {
    
    const isUpenn = window.location.href.includes("upenn");
    const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
    const isTripment = window.location.href.includes("tripment");
    const isHouston = window.location.href.includes("hhc");
    const isLcmc = window.location.href.includes("lcmc");
    const isNC = window.location.href.includes("clark");
    const isCHNW = window.location.href.includes("chnw");
    const isDecaturMorgan = window.location.href.includes("decaturmorganhospital");
    const isDoseSpot = window.location.href.includes("dosespot");
    const isMethodist = window.location.href.includes("methodist") || window.location.href.includes("medsavings");
    const isPrisma = window.location.href.includes("prismahealth");
    const isMDSX = window.location.href.includes("middlesexhealth");
    const isUIHealth = window.location.href.includes("uihealth");
    const isMHealthEmployees = window.location.href.includes("mhemployees");
    const isHealth = window.location.href.includes("health") || true;





    const domainName = isUpenn ? "Upenn" : isPapaJohn ? "PapaJohns" : isTripment ? "Tripment" : isHouston ? "HHC" : isLcmc ? "LCMC" : isNC ? "Clark" : isCHNW ? "CHNW" : isDecaturMorgan ? "DMH" : isDoseSpot ? "DoseSpot" : isMethodist ? "HCA" : isPrisma ? "Prisma" : isMDSX ? "Middlesex" : isUIHealth ? "CIPHER UI Health" : isMHealthEmployees ? "Middlesex" : isHealth ? "Treat" : "RxLink";

    const pid = isUpenn ? "UPENN" : isPapaJohn ? "PAPAJOHNS" : isHouston ? "HHC" : isTripment ? "TRIPMENT" : isLcmc ? "LCMC" : isNC ? "NCH" : isCHNW ? "CHNW" : isDecaturMorgan ? "DMH" : isDoseSpot ? "DoseSpot" : isMethodist ? "HCA" : isPrisma ? "PRIS" : isMDSX ? "MID" : isUIHealth ? "UIH" : isMHealthEmployees ? "CIPHER_MEM" : isHealth ? "TREAT" : "RXLINK";

    const defaultLogo = isUpenn ? upennLogo : isPapaJohn ? papajohnsLogo : isHouston ? houstonLogo : isTripment ? tripmentLogo : isLcmc ? lcmchealthlogo : isNC ? nclogo : isCHNW ? chnwLogo : isDecaturMorgan ? dmhlogo : isDoseSpot ? dosespotlogo : isMethodist ? mhslogo : isPrisma ? prismalogo : isMDSX ? mdsxlogo : isUIHealth ? uiHealth : isMHealthEmployees ? mdsxlogo : logo;

    const healthSystem = isUpenn ? "Upenn Medicine" :
        isPapaJohn ? "Papa John's United" :
            isTripment ? "Tripment Health" :
                isHouston ? "Housten Health System" :
                    isLcmc ? "LCMC Health" : isNC ? "Norton Clark" : isCHNW ? "Community Health Network" :
                        isDecaturMorgan ? "Decatur Morgan Hospital" : isDoseSpot ? "DoseSpot" : isMethodist ? "Methodist" : isPrisma ? "Prisma Health" :
                           isMDSX ? "Middlesex Health" : isUIHealth ? "University of Illinois Health" : isMHealthEmployees ? "Middlesex Health" : "RxLink";
    const altText = isUpenn ? "Upenn Medicine Logo" :
        isPapaJohn ? "Papa John's United Logo" :
            isTripment ? "Tripment Health Logo" :
                isHouston ? "Housten Health System Logo" :
                    isLcmc ? "LCMC Health Logo" : isNC ? "Norton Clark Logo" : isCHNW ? "Community Health Network Logo" :
                        isDecaturMorgan ? "Decatur Morgan Hospital Logo" : isDoseSpot ? "DoseSpot Logo" : isMethodist ? "Methodist Health Care Logo" : isPrisma ? "Prisma Health Logo" :
                        isMDSX ? "Middlesex Health Logo":  isUIHealth ? "University of Illinois Health Logo" : isMHealthEmployees ? "Middlesex Health Logo" : "RxLink Logo";

    return {
        pid,
        domainName,
        defaultLogo,
        healthSystem,
        altText
    };
}