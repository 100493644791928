import { useEffect, useState, useRef, useMemo } from "react";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, getUIFlags } from "../../reducers/auth";
import { resetPassword } from "../../reducers/resetPassword";
import { forgetPwd } from "../../reducers/forgotPassword";
import ResetPassword from "./ResetPassword";
import ForgetPassword from "./ForgetPassword";
import { fetchEnrollmentList } from "../../reducers/enrollmentList";
import ForgotTempPassword from "./forgotTempPassword";
import { domainName } from "../../environment";
import { successToast, errorToast } from "../toast/toast"
import "react-toastify/dist/ReactToastify.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { loginRouteVariants } from "../animationsVariants";
import { MotionText } from "../motion/MotionText";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  validateOTP,
  generateOtp,
  otpExpiredApi,
} from "../../reducers/validateOtp";
import { setShowPahrmacyMap, setShowResetPasswordModal } from "../../reducers/medication";
import { UsernameField, usernameValidation } from "../Fields/Username";
import {
  setIP, setAuth, setNewUser,
  setIsCalledAlready,
  setHasDob, setHasFirstName, setResetModalIsOpen
} from "../../reducers/global";
import { httpClientJava } from "../../environment";
import DateOfBirth from "../Fields/DateOfBirth";
import { StringToHTML } from "../../utilities/StringToHtml";
import { getDomainName } from "../../utilities/useGetDomain";
import TickCross from "../../utilities/TickCross";
import { setAnimatedLoading } from "../../reducers/global";
import Loader from "../Loader";
import { ariaAnnounce } from "../../utilities/useAnnounceText";
import LoadingComponent from "../LoadingComponent";

import { useGetUser } from "../../utilities/useGetUser";
import logo from "../../assets/images/logo.png";

const Login = () => {
  // let userInfo = localStorage.getItem("user");
  // var userDetail = userInfo && JSON.parse(userInfo);
  const isProd = process.env.REACT_APP_ENV === "PRODUCTION" ? true : false;

  const userDetail = useGetUser();
  console.log({ userDetail })
  const [isOpenResetPwdModal, setIsOpenResetPwdModal] = useState(false);
  const [isOpenForgotPwdModal, setIsOpenForgotPwdModal] = useState(false);
  const [resetPwd, setResetPasswd] = useState("");
  const [isTempReset, setIsTempReset] = useState(false);
  const [submitResetPasswordData, setSubmitResetPasswordData] = useState("");
  const [submitForgotPasswordData, setSubmitForgotPasswordData] = useState("");
  const [loading, setLoading] = useState(false);
  const [mask, setMask] = useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordRef = useRef(null);
  const usernameRef = useRef(null);
  const dobRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  const [errorMonth, setErrorMonth] = useState("121");
  const [errorYear, setErrorYear] = useState("121");
  const [errorDay, setErrorDay] = useState("121");


  const { domainName, defaultLogo, altText } = getDomainName();

  const [passType, setPassType] = useState("password");
  var numberPattern = /\d+/g;

  const isNewUser = useMemo(() => {
    return window.location.href.includes("?p=");
  }, [window.location.href]);

  const isCalledAlready = useSelector((state) => state.global.isCalledAlready);
  const hasDob = useSelector((state) => state.global.hasDob);
  const hasFirstName = useSelector((state) => state.global.hasFirstName);

  const resetModalIsOpen = useSelector((state) => state.global.resetModalIsOpen);

  useEffect(() => {
    ariaAnnounce(text);
  }, [])

  const getParamFromURL = (paramName) => {
    const url = window.location.href;
    const paramStart = url.indexOf(`?${paramName}=`);
    if (paramStart === -1) {
      return null; // Parameter not found in the URL
    }

    const paramEnd = url.indexOf('&', paramStart);
    const valueStart = paramStart + paramName.length + 2; // Add 2 for the "=" character

    if (paramEnd === -1) {
      return url.substring(valueStart);
    } else {
      return url.substring(valueStart, paramEnd);
    }
  }

  var patientHash = getParamFromURL("p");

  const validationSchema = Yup.object().shape({
    username: usernameValidation,
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters"),
  });

  const validationSchemaNewUser = hasDob ? Yup.object().shape({
    dob: Yup.string().required("Required"),
  }) : hasFirstName ? Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required")
  }) : null

  const showResetPasswordModal = useSelector((state) => state.medication.showResetPasswordModal);

  useEffect(() => {
    if (isNewUser && !isCalledAlready) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      const url = `patient/getUIFlags?urlHash=${patientHash}`;
      setLoading(true);

      dispatch(getUIFlags())
        .unwrap()
        .then((data) => {
          dispatch(setIsCalledAlready(true))
          console.log("urlFlag:", data)
          if (data.status == "OK") {
            if (data?.data.hasDob === 1) {
              dispatch(setHasDob(true))
            }
            else if (data.data.hasDob === 0) {
              dispatch(setHasFirstName(true))
            }
          }
          else if (data.status == "CONTINUE") {
            const body = {
              urlHash: patientHash,
              domainName: domainName
            }
            dispatch(login(body))
              .unwrap()
              .then((response) => {
                if (response.data) {
                  localStorage.setItem("user", JSON.stringify(response?.data));
                  dispatch(setShowResetPasswordModal(true));
                }
                else {
                  errorToast(response.message);
                }
              })
              .catch((err) => {
                formik.setFieldValue("password", "");
                formik.setTouched('password', false);
                console.log({ err })
              })
          }
          else if (data.status == "IM_USED") {

            window.location.replace("/#/");
          }
          else {
            errorToast(data?.message)
          }
          setLoading(false);

        }).catch((error) => {
          console.log()
          setLoading(false);

        }).finally(() => {
          setLoading(false);

        })
    }
  }, [isNewUser])


  const ctrls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  const formInitValues = !isNewUser ? { username: "", password: "" } : (isNewUser && hasDob) ? { dob: "" } : { firstName: "", lastName: "" };
  const formValidationSchema = isNewUser ? validationSchemaNewUser : validationSchema;
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      dob: "",
      firstName: "",
      lastName: ""
    },
    validationSchema: formValidationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data, { resetForm, setFieldValue }) => {
      if(loading) {
        console.log("returning");
        return
      }
      if (isNewUser && !hasDob && !hasFirstName) {
        const url = `patient/getUIFlags?urlHash=${patientHash}`;
        setLoading(true);
        httpClientJava.get(url)
          .then(({ data }) => {
            if (data.status == "OK") {
              if (data?.data.hasDob === 1) {
                setHasDob(true)
              }
              else if (data.data.hasDob === 0) {
                setHasFirstName(true)
              }
            }
            if (data.status == "CONTINUE") {
              const body = {
                urlHash: patientHash,
                domainName: domainName
              }
              dispatch(login(body))
                .unwrap()
                .then((response) => {
                  if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response?.data));
                    dispatch(setShowResetPasswordModal(true));
                  }
                  else {
                    // errorToast(response.message)
                    errorToast(<StringToHTML htmlString={response?.message} />);

                  }
                })
                .catch((err) => {
                  formik.setFieldValue("password", "");
                  formik.setTouched('password', false);
                  console.log({ err })
                })
            }
            else {
              // errorToast(data?.message)
              errorToast(<StringToHTML htmlString={data?.message} />);

            }
            setLoading(false);
          }).catch((error) => {
            console.log()
            setLoading(false);
          }).finally(() => {
            setLoading(false);
          })
        return
      }

      // setLoading(true);
      dispatch(setAnimatedLoading(true))
      let body = {}
      if (!isNewUser) {
        body =
        {
          username: data.username.match(numberPattern).join(""),
          password: data?.password,
          domainName: domainName
        };
      }
      else if (isNewUser && hasDob) {
        body = {
          dob: String(data?.dob.getFullYear()) + String(data?.dob.getMonth() + 1).padStart(2, '0') + String(data?.dob.getDate()).padStart(2, '0'),
          urlHash: patientHash,
          domainName: domainName
        }
      }
      else if (isNewUser && !hasDob && hasFirstName) {
        body = {
          firstName: data.firstName,
          lastName: data.lastName,
          urlHash: patientHash,
          domainName: domainName
        }
      }
      dispatch(login(body))
        .unwrap()
        .then((response) => {
          // setLoading(false);
          if (response.data != undefined)
            localStorage.setItem("user", JSON.stringify(response?.data));
          if (response?.data?.enrollmentStatus === "Unverified") {
            localStorage.setItem("user", JSON.stringify(response?.data));
            //errorToast("Please verify OTP");
            dispatch(generateOtp(body))
              .unwrap()
              .then((response) => {
              })
            localStorage.setItem("userBody", JSON.stringify(body));
            navigate("/otpVerify");
            // setLoading(false);
            dispatch(setAnimatedLoading(false))

            return;
          }
          // if (response?.statusCode === 307) {
          //   toast.success(response?.data.domainName, {
          //     position: toast.POSITION.BOTTOM_CENTER,
          //   });
          // window.location.replace(response?.data?.domainUrl)
          // return;
          // }
          if (response?.statusCode >= 200 && response?.statusCode <= 299 || response?.statusCode === 307) {
            // localStorage.setItem("user", JSON.stringify(response?.data));
            // setAuth(response?.data);
            dispatch(setAuth(response?.data))
            dispatch(setIP(response?.data.proxyIpAddress))
            // userDetail = response?.data;
            if (response?.data?.enrollmentStatus === "Pending") {
              // setIsOpenResetPwdModal(true);

              dispatch(setShowResetPasswordModal(true))
              // setLoading(false);
              // setIsTempReset(false);
              dispatch(setAnimatedLoading(false))

            } else if (response?.data?.enrollmentStatus === "Unverified") {
              navigate("/otpVerify");
              setLoading(false);
            } else {
              let condition = isProd ? response?.data?.hasInsurance : response?.data?.formSubmitted;
              if (condition) {
                if (response?.data?.hasPharmacy) {
                  dispatch(setShowPahrmacyMap(false));
                  // navigate("/medications");
                  window.location.replace("/#/medications")

                }
                else {
                  dispatch(setShowPahrmacyMap(true));
                  // navigate("/medications");
                  window.location.replace("/#/medications")

                }
              } else {
                // navigate("/insurance");
                window.location.replace("/#/insurance");
                // setLoading(false);
                dispatch(setAnimatedLoading(false))

              }
            }
          } else {
            // errorToast(response?.message)
            errorToast(<StringToHTML htmlString={response?.message} />);
            // formik.setFieldValue("dob", "");
            formik.setFieldValue("password", "");
            // setLoading(false);
            dispatch(setAnimatedLoading(false))

            formik.setTouched('password', true);
            usernameRef.current && usernameRef.current.focus();
            passwordRef.current && passwordRef.current.focus();
          }
        })
        .catch((e) => {
          // setLoading(false);
          dispatch(setAnimatedLoading(false))

        });
    },
  });

  const setResetPwd = (value) => {
    setIsOpenResetPwdModal(value);
  };

  useEffect(() => {
    if (submitForgotPasswordData?.number) {
      setLoading(true);
      dispatch(
        forgetPwd(
          submitForgotPasswordData?.number
        )
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.status === "OK") {
            localStorage.setItem("user", JSON.stringify(response?.data));
            // userDetail = response?.data;
            // successToast(response?.message);
            setIsOpenForgotPwdModal(false);
            // setIsTempReset(true);
            dispatch(setResetModalIsOpen(true))
            dispatch(setIP(response?.data.proxyIpAddress))
            successToast(response?.message);

          } else {

            errorToast(<StringToHTML htmlString={response?.message} />);
            setLoading(false);
          }
        });
    }
  }, [submitForgotPasswordData]);

  useEffect(() => {
    if (submitResetPasswordData?.password) {
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword ? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };
      setResetPasswd(body);
    }
  }, [submitResetPasswordData]);

  function checkEnrollmentStatus() {
    setLoading(true);
    let enrollmentListBody = {
      appUserSeqNo: userDetail?.appUserSeqNo,
    };
    dispatch(fetchEnrollmentList(enrollmentListBody))
      .unwrap()
      .then((res) => {
        setLoading(false);
        if (res?.data?.length > 0) {
          let obj = {
            ndc: res?.data[0]?.ndc,
            rxNorm: res?.data[0]?.rxNorm,
          };
          if (
            res.data.filter(
              (x) => x?.bin && x?.pcn && x?.groupNumber && x?.memberId
            )
          ) {
            navigate("/medications", { state: obj });
          } else {
            navigate("/insurance", { state: obj });
          }
        } else {
          // addToast(res?.message, { appearance: "error", autoDismiss: 5000 });
          errorToast(res?.message);
        }
      })
      .catch((err) => { });
  }

  useEffect(() => {
    if (resetPwd?.password) {
      setLoading(true);
      dispatch(resetPassword(resetPwd))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.statusCode >= 200 && response?.statusCode <= 299) {
            // response?.data && localStorage.setItem("user", JSON.stringify(response?.data));
            localStorage.setItem("user", JSON.stringify({ ...userDetail, token: response.data.token, refreshToken: response.data.refreshToken, dob: response.data.dob }));
            // setIsOpenResetPwdModal(false);
            dispatch(setShowResetPasswordModal(false));
            successToast(response?.message);
            // setIsTempReset(false);
            dispatch(setResetModalIsOpen(false))
            let condition = isProd ? userDetail?.hasInsurance : userDetail?.formSubmitted;
            if (condition) {
              // navigate("/medications");
              window.location.replace("/#/medications")
            } else {
              // navigate("/insurance");
              window.location.replace("/#/insurance")
            }
          } else {
            errorToast(<StringToHTML htmlString={response?.message} />);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [resetPwd]);

  const signUp = (e) => {
    navigate("/signUp");
  };


  const { isSubmitting, isValidating } = formik;


  const text = 'Personalized medication savings';
  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(formik.errors).length > 0) {
        handleInputFocus();
      }
    }
  }, [isSubmitting, isValidating]);

  const announceErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    // debugger
    if (liveRegion) {
      liveRegion.innerText = message;
      liveRegion.setAttribute('aria-live', 'assertive');
    }
  };



  const hideErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    if (liveRegion) {
      // liveRegion.innerText = 'Password must be 8 characters long and contain a mix of letters and numbers.';
      liveRegion.setAttribute('aria-live', 'off');
    }
  };



  const handleInputFocus = () => {
    let firstErrorField = Object.keys(formik?.errors)?.find(
      (fieldName) => formik.touched[fieldName]
    );
    if (firstErrorField) {
      switch (firstErrorField) {
        case "firstName":
          window.document.getElementById("userFirstNameInput").focus();
          ariaAnnounce(formik.errors.firstName);
          break;
        case "lastName":
          window.document.getElementById("userLastNameInput").focus();
          ariaAnnounce(formik.errors.lastName);
          break;
        case "password":
          window.document.getElementById("loginPasswordInput").focus();
          ariaAnnounce(formik.errors.password);
          // ariaAnnounce(formik.errors.password);
          break;
        case "username":
          window.document.getElementById("loginPhoneInput").focus();
          ariaAnnounce(formik.errors.username);
          break;
        case "dob":
          if (errorMonth || errorMonth == "121") {
            window.document.getElementById("loginmonthInput")?.focus();
            break;
          }
          if (errorDay || errorDay == "121") {
            window.document.getElementById("logindayInput")?.focus();
            break;
          }
          if (errorYear || errorYear == "121") {
            window.document.getElementById("loginyearInput")?.focus();
            break;
          }
          break;
        default:
          break;
      }
    }
  }

  const InputBag = () => {
    if (isNewUser && hasDob) {
      return (
        <>
          <p style={{ fontStyle: "italic", fontSize: 11, textAlign: "left" }}>Please enter your Date of Birth</p>
          <div style={{ width: '350px' }} className="forget_input_eye">
            <DateOfBirth formik={formik} fieldName={'dob'}
              enabled={true}
              id="login"
              ariaLabel="login"
              inputRef={dobRef}
              errorMonth={errorMonth}
              errorYear={errorYear}
              errorDay={errorDay}
              setErrorDay={setErrorDay}
              setErrorMonth={setErrorMonth}
              setErrorYear={setErrorYear}
              deskClass={"error-circle"}
              mobileClass={"error-circle"}
            />
          </div>

        </>
      )
    }
    else if (isNewUser && !hasDob && hasFirstName) {
      return (
        <>
          <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="mb-1 forget_input_eye form-group">
                <input
                  name="firstName"
                  type="text"
                  className={
                    "form-control custom-form-control login_input" +
                    (formik.errors.firstName && formik.touched.firstName
                      ? " is-invalid"
                      : "")
                  }
                  id="userFirstNameInput"
                  placeholder="Enter your first name"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  aria-label="First Name"
                  onFocus={() => formik.errors.firstName && ariaAnnounce("Required")}
                // onBlur={() => hideErrorMessage('firstNameError')}
                />
                {formik.errors.firstName && formik.touched.firstName && <div className="invalid-feedback"
                  // role="alert" 
                  id="firstNameError" aria-live="off">
                  {formik.errors.firstName && formik.touched.firstName
                    ? formik.errors.firstName
                    : null}
                </div>}
                {/* {formik.errors.firstName && formik.touched.firstName && (
                  <span className="badge bg-danger rounded-pill error-circle">x</span>
                )}
                {!formik.errors.firstName && formik.touched.firstName && (
                  <span className="badge bg-success rounded-pill error-circle">✔</span>
                )} */}
                {TickCross(formik, "firstName", "error-circle")}
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="mb-1 forget_input_eye form-group">
                <input
                  name="lastName"
                  type="text"
                  className={
                    "form-control custom-form-control login_input" +
                    (formik.errors.lastName && formik.touched.lastName
                      ? " is-invalid"
                      : "")
                  }
                  id="userLastNameInput"
                  placeholder="Enter your last name"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  aria-label="Last Name"
                  onFocus={() => formik.errors.lastName && ariaAnnounce("Required")}
                // onBlur={() => hideErrorMessage('lastNameError')}
                />
                {formik.errors.lastName && formik.touched.lastName &&
                  <div className="invalid-feedback"
                    // role="alert" 
                    id="lastNameError" aria-live="off">
                    {formik.errors.lastName && formik.touched.lastName
                      ? formik.errors.lastName
                      : null}
                  </div>}

                {TickCross(formik, "lastName", "error-circle")}

              </div>
            </div>
          </div>
        </>
      )
    }
  }

  const changePassType = () => {
    if (passType == "password") {
      setPassType("text")
    }
    else {
      setPassType("password")
    }
  }


  return (
    <>
      {loading ? <LoadingComponent /> : <>
        <motion.section className=""
          // variants={loginRouteVariants}
          // initial="initial"
          // animate="final"
          initial={{ y: "-100vh" }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
        // role="main"
        // id="content"
        // aria-labelledby="headingToAnnounce"
        // aria-describedby="userLoginForm"
        >
          <div className="login_section" >
            <div id="headingToAnnounce" style={{ position: 'absolute', left: '-9999px', zIndex: '-1' }} />

            <form onSubmit={formik.handleSubmit} id="userLoginForm"

            >
              <div className="row g-0">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="login_section_inner">
                    <div className="row g-0">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="logo">
                          <motion.img
                            src={defaultLogo}
                            className="img-fluid"
                            alt={altText}
                            initial={{ y: -100 }}
                            animate={{ y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                          />
                        </div>
                        <MotionText text={text}
                          ctrls={ctrls}
                          wordAnimation={wordAnimation}
                          characterAnimation={characterAnimation}
                          cref={ref}
                          id="headingToAnnounceId"
                        />
                      </div>
                    </div>
                    {!isNewUser &&
                      // <UsernameField
                      //   formik={formik}
                      //   mask={mask}
                      //   setMask={setMask}
                      //   id="loginPhoneInput"
                      //   ariaLabel="Mobile Phone Number"
                      //   inputRef={usernameRef}
                      // />
                      <div className="forget_input_eye">

                        <InputMask
                          name='username'
                          className={`form-control  custom-form-control ${true && "disabled-input"}` +
                            (formik.errors.username && formik.touched.username
                              ? " is-invalid"
                              : "")}
                          mask="(999) 999-9999"
                          maskChar={null}
                          placeholder="Mobile phone number"
                          onChange={formik.handleChange}
                          value={formik.values.username}
                          aria-label="Mobile Phone Number"
                          inputRef={usernameRef}
                          id="loginPhoneInput"
                        >
                        </InputMask>
                        {formik.errors.username && formik.touched.username && <div className="invalid-feedback" aria-live="off" id="usernameError">
                          {formik.errors.username && formik.touched.username
                            ? formik.errors.username
                            : null}
                        </div>}
                        {TickCross(formik, "username", "error-circle")}

                      </div>
                    }
                    {!isNewUser ? <div className="row g-0">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="forget_input_eye mb-1  form-group">
                          <input
                            name="password"
                            type={passType}
                            className={
                              "form-control custom-form-control" +
                              (formik.errors.password &&
                                formik.touched.password
                                ? " is-invalid"
                                : "")
                            }
                            id="loginPasswordInput"
                            placeholder="Enter your password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            aria-label="Password"
                            ref={passwordRef}
                            onFocus={() => ariaAnnounce(formik.errors.password)}
                          // onBlur={() => hideErrorMessage('loginPass-error')}
                          />
                          {formik.errors.password && formik.touched.password && <div id="loginPass-error" className="invalid-feedback"
                          // role="alert"
                          >
                            {formik.errors.password && formik.touched.password
                              ? formik.errors.password
                              : null}
                          </div>}

                          <i><a href="javascript:void(0)" onClick={() => changePassType()}
                            aria-label={passType == "password" ? "show Password" : "hide password"}
                            className={passType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                            style={{ top: 13, color: "#77aca2" }}
                          ></a></i>
                          {/* {formik.errors.password && formik.touched.password && (
                              <span className="badge bg-danger rounded-pill error-circle">x</span>
                            )}
                            {!formik.errors.password && formik.touched.password && (
                              <span className="badge bg-success rounded-pill error-circle">✔</span>
                            )} */}
                          {TickCross(formik, "password", "error-circle")}
                        </div>
                      </div>
                    </div> : InputBag()}
                    <div className="row g-0">
                      {/* <motion.div className="col-sm-12 col-md-12 col-lg-12" */}
                      <div className="col-sm-12 col-md-12 col-lg-12"
                        initial={{ y: 50 }}
                        transition={{ type: "spring", duration: 0.8 }}
                        animate={{ y: 0 }}
                        whileTap={{
                          scale: 0.95,
                          transition: { duration: 0.1 },
                        }}                        >
                        <button
                          className="btn_success"
                          type="submit"
                          form="userLoginForm"
                        >
                          Sign In
                        </button>
                      </div>
                      {/* </motion.div> */}

                    </div>
                    {!isNewUser && <div className="row g-0">
                      {/* <motion.div className="col-sm-12 col-md-12 col-lg-12" */}
                      <div className="col-sm-12 col-md-12 col-lg-12"
                        initial={{ y: 50 }}
                        transition={{ type: "spring", duration: 0.8 }}
                        animate={{ y: 0 }}
                        whileTap={{
                          scale: 0.95,
                          transition: { duration: 0.1 },
                        }}
                      >
                        <button
                          className="btn_default"
                          onClick={() => signUp()}
                          type="button"
                        >
                          Sign Up
                        </button>
                      </div>
                      {/* </motion.div> */}

                    </div>}
                    {/* <div className="row g-0">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p
                            className="password pointer"
                            onClick={() => signUp()}
                          >
                            <a>Don’t have a password? Click here to sign up</a>
                          </p>
                        </div>
                      </div> */}
                    <div className="row g-0">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <p
                          className="password pointer m-0"
                        >
                          <a className="txtDecoration" href="javascript:void(0)"
                            onClick={() => setIsOpenForgotPwdModal(true)}>Forgot Password?</a>
                        </p>
                      </div>
                    </div>
                    {domainName == "HCA" && <div className="row g-0" >
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12" >
                        <p
                          className="password pointer m-0"
                          style={{ paddingTop: 10 }}
                        >
                          <span
                            className="txtDecoration"
                            style={{ fontSize: 13 }}
                          >powered by
                            <img
                              src={logo}
                              className="img-fluid"
                              style={{ maxWidth: 68, marginLeft: 2 }}
                            /></span>
                        </p>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </motion.section>

        {isOpenForgotPwdModal && <ForgetPassword
          show={isOpenForgotPwdModal}
          close={() => {
            setIsOpenForgotPwdModal(false);
          }}
          setSubmitForgotPasswordData={setSubmitForgotPasswordData}
        />}

        {showResetPasswordModal && <ResetPassword
          show={showResetPasswordModal}
          close={() => {
            // if(isOpenResetPwdModal)
            // setResetPwd(false);
            dispatch(setShowResetPasswordModal(false));
          }}
          setSubmitResetPasswordData={setSubmitResetPasswordData}
        />}

        {resetModalIsOpen && <ForgotTempPassword
          show={resetModalIsOpen}
          close={() => dispatch(setResetModalIsOpen(false))}
          setSubmitResetPasswordData={setSubmitResetPasswordData}
        />}
      </>}
    </>
  );
};

export default Login;
