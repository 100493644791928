import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { saveInsurance } from "../../reducers/submitInsurance";
import "react-toastify/dist/ReactToastify.css";
import Header from "../partials/Header";
import { motion, AnimatePresence, color } from "framer-motion";
import { MotionButton } from "../motion/MotionButton";

import tabHealth from "../../assets/images/tab-health.svg";
import tabotp from "../../assets/images/tab-otp.svg";
import mobHealth from "../../assets/images/mob-health.svg";
import Group831 from "../../assets/images/Group831.png";
import { setShowPahrmacyMap } from "../../reducers/medication";
import { fetchInsuranceInfo } from "../../reducers/getInsuranceInfo";
import { setInsuranceInfo } from "../../reducers/global";

import { genderOptions } from "./content";
import "./insurance.css";
import Form from "react-bootstrap/Form";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { successToast, errorToast } from "../toast/toast";
import DateOfBirth from "../Fields/DateOfBirth";
import { useStyleMediaQuery } from "../coupan/hooks";
import TickCross from "../../utilities/TickCross";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
import { useGetUser } from "../../utilities/useGetUser";
import { store } from "../../store";
import { saveProfile } from "../../reducers/submitProfile"
import Spinner from 'react-bootstrap/Spinner';
import PopoverInsurance from "../Popover";

const Insurance = () => {
  const [insurance, setInsurance] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const user = useGetUser();
  const hasPharmacy = user?.hasPharmacy;
  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 575 });
  const { windowWidth } = useWindowWidth();
  const isTab = windowWidth > 766 && windowWidth <= 992;
  const isDesktop = windowWidth > 992;
  const isPhone = (isTab == false && isDesktop == false);



  const [errorMonth, setErrorMonth] = useState("121");
  const [errorYear, setErrorYear] = useState("121");
  const [errorDay, setErrorDay] = useState("121");

  const isProd = process.env.REACT_APP_ENV === "PRODUCTION" ? true : false;


  useEffect(() => {
    user?.firstName && formik.setFieldValue("firstName", user?.firstName);
    user?.lastName && formik.setFieldValue("lastName", user?.lastName);
    user?.middleName && formik.setFieldValue("middleName", user?.middleName);
    user?.gender && formik.setFieldValue("gender", user?.gender);
    if (/^\d{8}$/.test(user?.dob) && parseInt(user?.dob)) {
      const year = parseInt(user.dob.substr(0, 4));
      const month = parseInt(user.dob.substr(4, 2)) - 1;
      const day = parseInt(user.dob.substr(6, 2));
      const convertedDate = new Date(year, month, day);
      formik.setFieldValue("dob", convertedDate)
    }
    dispatch(fetchInsuranceInfo({ appUserSeqNo: user?.appUserSeqNo })).unwrap().then((res) => {
      if (res.data && res.data.length) {
        let {
          memberId,
          groupNumber,
          pcn,
          bin,
          firstName,
          lastName,
          middleName,
          dob,
          gender,
        } = res.data[0];

        res.data[0] && dispatch(setInsuranceInfo(res.data[0]));

        firstName && formik.setFieldValue("firstName", firstName)
        lastName && formik.setFieldValue("lastName", lastName)
        middleName && formik.setFieldValue("middleName", middleName)
        pcn && formik.setFieldValue("pcn", pcn)
        bin && formik.setFieldValue("bin", bin)
        groupNumber && formik.setFieldValue("groupNumber", groupNumber)
        gender && formik.setFieldValue("gender", gender)
        memberId && formik.setFieldValue("memberId", memberId)
        if (/^\d{8}$/.test(dob) && parseInt(dob)) {
          const year = parseInt(dob.substr(0, 4));
          const month = parseInt(dob.substr(4, 2)) - 1;
          const day = parseInt(dob.substr(6, 2));
          const convertedDate = new Date(year, month, day);
          formik.setFieldValue("dob", convertedDate)
        }
      }
    })
  }, [])



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${mm}-${dd}-${yyyy}`;
  }

  function onChangeValue(event) {
    setInsurance(event.target.value);
    // console.log(event.target.value);
    if (event.target.value === "No") {
      navigate("/medications");
    }
  }

  const onSkip = () => {
    setInsurance("NO");
    // console.log("test");
    navigate("/medications");
  };

  const NumberVerify = () => {
    navigate("/otpVerify");
  };

  const CopayProgram = () => {
    formik.resetForm();
    if (hasPharmacy)
      navigate("/medications");
    else {
      dispatch(setShowPahrmacyMap(true))
      navigate("/medications")
    }
  };

  const medication = () => {
    navigate("/medications");
  };
  const Insurance = () => {
    navigate("/insurance");
  };

  const FindPharmacy = () => {
    navigate("/FindPharmacy");
  };

  const submit = () => {
    // navigate(`/medications`, { state: state });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    middleName: Yup.string(),
    lastName: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    dob: Yup.string().required("Required"),
    // username: Yup.string()
    //     .required('Username is required')
    //     .min(6, 'Username must be at least 6 characters')
    //     .max(20, 'Username must not exceed 20 characters'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),
    bin: Yup.string().required("Required"),
    pcn: Yup.string(),
    groupNumber: Yup.string().required("Required"),
    memberId: Yup.string().required("Required"),
    // confirmPassword: Yup.string()
    //     .required('Confirm Password is required')
    //     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      bin: "",
      pcn: "",
      groupNumber: "",
      memberId: "",
    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      if (loading) return;
      setLoading(true);
      let body = {
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        dob: String(data?.dob.getFullYear()) + String(data?.dob.getMonth() + 1).padStart(2, '0') + String(data?.dob.getDate()).padStart(2, '0'),
        gender: data?.gender,
        bin: data?.bin,
        pcn: data?.pcn,
        groupNumber: data?.groupNumber,
        memberId: data?.memberId,
        appUserSeqNo: user?.appUserSeqNo,
      };
      dispatch(saveInsurance(body))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.data) {
            dispatch(setInsuranceInfo(response.data));
            let user = JSON.parse(localStorage.getItem("user"));
            localStorage.setItem("user", JSON.stringify({ ...user, dob: response?.data?.dob, hasInsurance: true, gender: response?.data?.gender }))
            navigate("/medications");
            successToast("Insurance has been added successfully.", "showAlert");
          } else if (response?.message != "OK") {
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    },
  });

  const { isSubmitting, isValidating } = formik;


  useEffect(() => {
    if (isSubmitting && isValidating) {
      if (Object.keys(formik.errors).length > 0) {
        const firstErrorField = Object.keys(formik.errors).find(
          (fieldName) => formik.touched[fieldName]
        );
        if (firstErrorField) {
          switch (firstErrorField) {
            case "dob":
              if (errorMonth || errorMonth == "121") {
                window.document.getElementById("insurancemonthInput")?.focus();
                break;
              }
              if (errorDay || errorDay == "121") {
                window.document.getElementById("insurancedayInput")?.focus();
                break;
              }
              if (errorYear || errorYear == "121") {
                window.document.getElementById("insuranceyearInput")?.focus();
                break;
              }
              break;
            case "firstName":
              window.document.getElementById("insuranceFirstNameInput")?.focus();
              break;
            case "lastName":
              window.document.getElementById("insuranceLastNameInput")?.focus();
              break;
            case "gender":
              window.document.getElementById("insuranceGenderInput")?.focus();
              break;
            case "bin":
              window.document.getElementById("insuranceBinInput")?.focus();
              break;
            case "groupNumber":
              window.document.getElementById("insuranceGroupNumberInput")?.focus();
              break;
            case "memberId":
              window.document.getElementById("insuranceMemberIdInput")?.focus();
              break;
            default:
              break;
          }
        }
      }
    }
  }, [isSubmitting, isValidating]);

  const announceErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    if (liveRegion) {
      liveRegion.innerText = message;
      liveRegion.setAttribute('aria-live', 'assertive');
    }
  };



  const hideErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    if (liveRegion) {
      // liveRegion.innerText = 'Password must be 8 characters long and contain a mix of letters and numbers.';
      liveRegion.setAttribute('aria-live', 'off');
    }
  };

  return (
    <>
      <AnimatePresence mode="wait">
        <section className="fadeAni" >
          <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="">&nbsp;</div>
            </div>
          </div>
          <div>
            <div className="row g-0" style={{ marginTop: "3vw" }}>
              <div className="col-12 col-sm-12 col-md-12 col-lg-5 p-0 autoMargin">
                <div className="vector-image "  >
                  <motion.img
                    key={tabHealth}
                    // src={image.src}
                    initial={{ opacity: 0, x: 500 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0 }}
                    src={tabHealth}
                    transition={{ duration: 0.8, type: "tween" }}
                    className="img-fluid  Vector"
                    alt=""
                  />
                  {/* </AnimatePresence> */}
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-7 paddingZero autoMargin"
                style={{ zIndex: 100 }}
              >
                <div className="">

                  <motion.div
                    className="insurance_inner_main insurance-padding"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <form onSubmit={formik.handleSubmit} id="insuranceForm">
                      <div className="row" id="cc-info-div">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <motion.div
                            className="insurance_inner"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.7 }}
                          >
                            <div className="row webView">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                {
                                  !hasPharmacy && <div className="page_counter" style={{ marginTop: -30, paddingBottom: 20 }}>
                                    <h4 style={{ fontSize: 14 }}>1</h4>
                                    <motion.h3
                                      className="active"
                                      initial={{ scale: 0.5 }}
                                      animate={{ scale: 1 }}
                                      transition={{ duration: 1 }}
                                    >
                                      2
                                    </motion.h3>
                                    <h4 style={{ fontSize: 14 }}>3</h4>
                                  </div>
                                }
                              </div>
                            </div>
                            <div role="alert">
                              <motion.div
                                className="row"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.8 }}
                              >
                                <div className={`col-12 col-sm-12 col-md-12 col-lg-12 ${!hasPharmacy && "mt-4"}`}>
                                  <h1>Do you have health insurance?</h1>
                                </div>
                              </motion.div>
                              <motion.div
                                className="row"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.8, delay: 0.15 }}
                              >
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <p>
                                    Although not required, adding your insurance
                                    guarantees we can present the best medication
                                    savings options for you
                                  </p>
                                  <p>
                                    Please use the same information as it appears
                                    on your insurance card:
                                  </p>
                                </div>
                              </motion.div>
                            </div>
                            <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="firstName"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.firstName && formik.touched.firstName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="First Name*"
                                  onChange={formik.handleChange}
                                  value={formik.values.firstName}
                                  id="insuranceFirstNameInput"
                                  aria-label="First Name"
                                  aria-describedby="firstNameError"
                                  onFocus={() => announceErrorMessage('firstNameError', 'Required')}
                                  onBlur={() => hideErrorMessage('firstNameError')}
                                />
                                {formik.errors.firstName && formik.touched.firstName &&
                                  <div className="invalid-feedback" aria-live="off" id="firstNameError">
                                    {" "}
                                    {formik.errors.firstName && formik.touched.firstName
                                      ? formik.errors.firstName
                                      : null}{" "}
                                  </div>
                                }
                                {TickCross(formik, "firstName", "error-circle-insurance")}
                              </div>
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="middleName"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.middleName && formik.touched.middleName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Middle Name"
                                  onChange={formik.handleChange}
                                  value={formik.values.middleName}
                                  id="insuranceMiddleNameInput"
                                  aria-label="Middle Name"
                                />
                                <div className="invalid-feedback" role="alert">
                                  {" "}
                                  {formik.errors.middleName && formik.touched.middleName
                                    ? formik.errors.middleName
                                    : null}
                                </div>
                                {/* {TickCross(formik, "middleName", "error-circle-insurance")} */}

                              </div>
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="lastName"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.lastName &&
                                      formik.touched.lastName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Last Name*"
                                  onChange={formik.handleChange}
                                  value={formik.values.lastName}
                                  id="insuranceLastNameInput"
                                  aria-label="Last Name"
                                  onFocus={() => announceErrorMessage('lastNameError', 'Required')}
                                  onBlur={() => hideErrorMessage('lastNameError')}
                                />
                                {formik.errors.lastName &&
                                  formik.touched.lastName && <div className="invalid-feedback" aria-live="off" id="lastNameError">
                                    {" "}
                                    {formik.errors.lastName &&
                                      formik.touched.lastName
                                      ? formik.errors.lastName
                                      : null}
                                  </div>}
                                {TickCross(formik, "lastName", "error-circle-insurance")}
                              </div>
                              {isMobile && <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <select
                                  name="gender"
                                  style={{ color: formik.values.gender === "" && "gray", width: "90%" }}
                                  defaultValue=""
                                  className={
                                    "form-select  custom-form-control custom-form-select" +
                                    (formik.errors.gender && formik.touched.gender
                                      ? " is-invalid form-select-is-invalid"
                                      : "")
                                  }
                                  onChange={formik.handleChange}
                                  value={formik.values.gender}
                                  id="insuranceGenderInput"
                                  aria-label="Birth Sex"
                                  onFocus={() => announceErrorMessage('genderError', 'Required')}
                                  onBlur={() => hideErrorMessage('genderError')}
                                >
                                  <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                  {genderOptions.map(option => (
                                    <option key={option.value} value={option.value} style={{ color: "black" }}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                {formik.errors.gender && formik.touched.gender && <div className="invalid-feedback" role="alert" id="genderError" aria-live="off">
                                  {" "}
                                  {formik.errors.gender && formik.touched.gender
                                    ? formik.errors.gender
                                    : null}{" "}
                                </div>}
                                {TickCross(formik, "gender", "error-circle-insurance")}
                              </div>}
                            </motion.div>
                            <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              {!isMobile && <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group forget_input_eye">
                                <select
                                  name="gender"
                                  style={{ color: formik.values.gender === "" && "gray", width: "90%" }}
                                  defaultValue=""
                                  className={
                                    "form-select  custom-form-control custom-form-select" +
                                    (formik.errors.gender && formik.touched.gender
                                      ? " is-invalid form-select-is-invalid"
                                      : "")
                                  }
                                  onChange={formik.handleChange}
                                  value={formik.values.gender}
                                  id="insuranceGenderInput"
                                  aria-label="Birth Sex"
                                  onFocus={() => announceErrorMessage('genderError', 'Required')}
                                  onBlur={() => hideErrorMessage('genderError')}
                                >
                                  <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                  {genderOptions.map(option => (
                                    <option key={option.value} value={option.value} style={{ color: "black" }}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                {formik.errors.gender && formik.touched.gender && <div className="invalid-feedback" role="alert" id="genderError" aria-live="off">
                                  {" "}
                                  {formik.errors.gender && formik.touched.gender
                                    ? formik.errors.gender
                                    : null}{" "}
                                </div>}
                                {TickCross(formik, "gender", "error-circle-insurance")}
                              </div>}
                              <div className="col-12 col-sm-8 col-md-8 col-lg-8 form-group forget_input_eye" style={{ width: "90%" }}>
                                <DateOfBirth
                                  formik={formik}
                                  fieldName={'dob'}
                                  enabled={true}
                                  id="insurance"
                                  errorMonth={errorMonth}
                                  errorYear={errorYear}
                                  errorDay={errorDay}
                                  setErrorDay={setErrorDay}
                                  setErrorMonth={setErrorMonth}
                                  setErrorYear={setErrorYear}
                                  deskClass={"error-circle-dob"}
                                  mobileClass={"error-circle-dob-mobile"}
                                />
                              </div>
                            </motion.div>
                            <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="bin"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.bin && formik.touched.bin
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="BIN*"
                                  onChange={formik.handleChange}
                                  value={formik.values.bin}
                                  id="insuranceBinInput"
                                  aria-label="BIN"
                                  onFocus={() => announceErrorMessage('binError', 'Required')}
                                  onBlur={() => hideErrorMessage('binError')}
                                />
                                {formik.errors.bin && formik.touched.bin && <div className="invalid-feedback" role="alert" id="binError" aria-live="off">
                                  {" "}
                                  {formik.errors.bin && formik.touched.bin
                                    ? formik.errors.bin
                                    : null}{" "}
                                </div>}
                                {TickCross(formik, "bin", "error-circle-insurance")}
                              </div>
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group">
                                <input
                                  type="text"
                                  name="pcn"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.pcn && formik.touched.pcn
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="PCN"
                                  onChange={formik.handleChange}
                                  value={formik.values.pcn}
                                  id="insurancePcnInput"
                                  aria-label="PCN"
                                />
                                <div className="invalid-feedback" role="alert">
                                  {" "}
                                  {formik.errors.pcn && formik.touched.pcn
                                    ? formik.errors.pcn
                                    : null}
                                </div>
                              </div>
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group forget_input_eye">
                                <input
                                  type="text"
                                  name="groupNumber"
                                  style={{ width: "90%" }}
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.groupNumber &&
                                      formik.touched.groupNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Group*"
                                  onChange={formik.handleChange}
                                  value={formik.values.groupNumber}
                                  id="insuranceGroupNumberInput"
                                  aria-label="Group"
                                  onFocus={() => announceErrorMessage('groupError', 'Required')}
                                  onBlur={() => hideErrorMessage('groupError')}
                                />
                                {formik.errors.groupNumber &&
                                  formik.touched.groupNumber && <div className="invalid-feedback" role="alert" aria-live="off" id="groupError">
                                    {" "}
                                    {formik.errors.groupNumber &&
                                      formik.touched.groupNumber
                                      ? formik.errors.groupNumber
                                      : null}
                                  </div>}
                                {TickCross(formik, "groupNumber", "error-circle-insurance")}
                              </div>
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group forget_input_eye">
                                <input
                                  type="text"
                                  style={{ width: "90%" }}
                                  name="memberId"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.memberId &&
                                      formik.touched.memberId
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Member ID*"
                                  onChange={formik.handleChange}
                                  value={formik.values.memberId}
                                  id="insuranceMemberIdInput"
                                  aria-label="Member ID"
                                  onFocus={() => announceErrorMessage('memberError', 'Required')}
                                  onBlur={() => hideErrorMessage('memberError')}
                                />
                                {formik.errors.memberId &&
                                  formik.touched.memberId && <div className="invalid-feedback" role="alert" id="memberError" aria-live="off">
                                    {" "}
                                    {formik.errors.memberId &&
                                      formik.touched.memberId
                                      ? formik.errors.memberId
                                      : null}
                                  </div>}
                                {TickCross(formik, "memberId", "error-circle-insurance")}
                              </div>
                              {(!isTab && !isDesktop) && <div style={{ marginBottom: -10, marginTop: 6 }}>
                                {/* <span style={{ fontSize: 13, fontWeight: 400 }}>Where do I find my insurance information? */}
                                  <span>
                                    <PopoverInsurance place={"left"} />
                                  </span>
                                {/* </span> */}
                              </div>}
                            </motion.div>
                            {isTab && <div style={{ marginBottom: -10, marginTop: 6 }}>
                              {/* <span style={{ fontSize: 13, fontWeight: 400 }}>Where do I find my insurance information? */}
                                <span>
                                  <PopoverInsurance place={"left"} />
                                </span>
                              {/* </span> */}
                            </div>}
                            <motion.div
                              className="button_div insurance_inner_main_btn mt-4 g-0"
                              id="cc-info-div-smbt"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.7 }}
                            >
                              <div className="margin-right">
                                {/* <MotionButton> */}
                                <button
                                  className="btn_success"
                                  type="submit"
                                  onClick={() => submit()}
                                >
                                  Submit
                                </button>
                                {/* </MotionButton> */}
                              </div>

                              <div className="margin-right webView">
                                {/* <MotionButton> */}
                                <button
                                  className="btn_default NIbutton "
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  No&nbsp;insurance
                                </button>
                                {/* </MotionButton> */}
                              </div>
                              <div className="margin-right webView">
                                {/* <MotionButton> */}
                                <button
                                  type="button"
                                  className="btn_default NIbutton"
                                  onClick={() => CopayProgram()}
                                >
                                  Skip
                                </button>
                                {/* </MotionButton> */}
                              </div>
                              {/* for <obile View */}
                              <div className="row mobileView">
                                <div className="col-6">
                                  {/* <MotionButton> */}
                                  <button
                                    className="btn_default "
                                    type="button"
                                    onClick={() => CopayProgram()}
                                  >
                                    No&nbsp;insurance
                                  </button>
                                  {/* </MotionButton> */}
                                </div>
                                <div className="col-6">
                                  {/* <MotionButton> */}
                                  <button
                                    type="button"
                                    className="btn_default "
                                    onClick={() => CopayProgram()}
                                  >
                                    Skip
                                  </button>
                                  {/* </MotionButton> */}
                                </div>
                              </div>

                            </motion.div>
                            {isTab && !isProd &&
                              <HCDLSection insuranceValues={formik.values} onSkip={CopayProgram} isTab={true} />
                            }
                          </motion.div>
                          {isDesktop && <div>
                            {/* <span style={{ fontSize: 13 }}>Where do I find my insurance information? */}
                              <span>
                                <PopoverInsurance place={"left"} />
                              </span>
                            {/* </span> */}
                          </div>}
                          <motion.div
                            className="button_div insurance_inner_main_btn2  g-0"
                            id="cc-info-div-smbt"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.7 }}
                          >
                            <div className="margin-right">
                              <button
                                className="btn_success"
                                type="submit"
                                onClick={() => submit()}
                              >
                                Submit
                              </button>
                            </div>

                            <div className="margin-right webView">
                              {/* <MotionButton> */}
                              <button
                                className="btn_default NIbutton "
                                type="button"
                                onClick={() => CopayProgram()}
                              >
                                No&nbsp;insurance
                              </button>
                              {/* </MotionButton> */}
                            </div>
                            <div className="margin-right webView">
                              {/* <MotionButton> */}
                              <button
                                className="btn_default NIbutton"
                                type="button"
                                onClick={() => CopayProgram()}
                              >
                                Skip
                              </button>
                              {/* </MotionButton> */}
                            </div>
                            {/* for mobile View */}
                            <div className="row mobileView">
                              <div className="col-6">
                                {/* <MotionButton> */}
                                <button
                                  className="btn_default "
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  No&nbsp;insurance
                                </button>
                                {/* </MotionButton> */}
                              </div>
                              <div className="col-6">
                                {/* <MotionButton> */}
                                <button
                                  className="btn_default "
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  Skip
                                </button>
                                {/* </MotionButton> */}
                              </div>
                              {isPhone && !isProd &&
                                <HCDLSection insuranceValues={formik.values} onSkip={CopayProgram} isPhone={true} />
                              }
                            </div>
                            {/* <div>
                                  <h1 style={{ fontSize: 20, color: "#4b4c4d", fontWeight: 600, letterSpacing: 0.2 }}>To refect the most current insurance coverage, you can enroll with our partner, Healthcare Download</h1>
                                </div> */}
                          </motion.div>
                          {isDesktop && !isProd &&
                            <HCDLSection insuranceValues={formik.values} onSkip={CopayProgram} />
                          }
                        </div>
                      </div>

                    </form>

                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileView">
            <div className="row g-0">
              <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
                <div className="dot-main">
                  <span className="dot"></span>
                  <span className="dot dotActive"></span>
                  <span className="dot"></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimatePresence>
    </>
  );
};


export default Insurance;


const HCDLSection = ({ insuranceValues, onSkip, isPhone, isTab }) => {
  const user = useGetUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(user?.hcdlFlag);
  const [loading, setLoading] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);

  const reduxState = store.getState();
  const insuranceInfo = reduxState.global.insuranceInfo;

  const submitHCDL = () => {
    if (!checked) {
      setCheckboxError(true);
      return;
    }
    if (loading) return;
    setLoading(true);
    const windowRef = window.open();
    let body = {
      insInfoSeqNo: insuranceInfo?.insInfoSeqNo,
      appUserSeqNo: user?.appUserSeqNo,
      patientSeqNo: user?.patientSeqNo,
      firstName: insuranceValues?.firstName,
      middleName: insuranceValues?.middleName,
      lastName: insuranceValues?.lastName,
      dob: insuranceValues?.dob ? String(insuranceValues?.dob?.getFullYear()) + String(insuranceValues?.dob?.getMonth() + 1).padStart(2, '0') + String(insuranceValues?.dob?.getDate()).padStart(2, '0') : '',
      gender: insuranceValues?.gender,
      address: insuranceInfo?.address,
      memberId: insuranceValues?.memberId,
      groupNumber: insuranceValues?.groupNumber,
      bin: insuranceValues?.bin,
      pcn: insuranceValues?.pcn,
      hasAlerts: false,
      hcdlFlag: checked
    }
    dispatch(saveProfile(body))
      .unwrap()
      .then((response) => {
        setLoading(false);
        if (response?.statusCode === 200 & response?.status === "OK")
          if (response?.statusCode === 200 & response?.status === "OK") {
            localStorage.setItem("user", JSON.stringify({ ...user, dob: response?.data?.dob, gender: response?.data?.gender, hcdlFlag: response?.data?.hcdlFlag }));
            if (response?.data?.hcdlEnrollmentLink) {
              windowRef.location = response?.data?.hcdlEnrollmentLink;
            }
            navigate("/medications");
          } else if (response?.status !== "OK") {
            errorToast(response?.message);
          }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  return (
    <div className="hcdl-section mt-3">

      <div className={`col-12 col-sm-12 col-md-12 col-lg-12 mb-1`}>
        <h1 style={{ fontSize: 20, color: "#4b4c4d", fontWeight: 600, letterSpacing: 0.2, lineHeight: 1.1 }}>To refect the most current insurance coverage, you can enroll with our partner, Healthcare Download</h1>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12" >
        <input type="checkbox" aria-labelledby={""} name='terms' checked={checked}
          className="p-1 mr-2" onChange={(e) => {
            setChecked(e.target.checked);
            if (e.target.checked === true) {
              setCheckboxError(false);
            }
          }
          }
          id="hcdltermsCheckBox" aria-label="terms"
        />
        <p style={{ marginLeft: 4, display: "inline", color: checkboxError ? "red" : "" }}>
          By checking the box and submitting your request, you agree to let us share you name and phone number with
          Healthcare Download to simplify your registration on their site.
        </p>

        <br />
        <p style={{ color: checkboxError ? "red" : "" }}>
          When your registration is complete, please click the link on their page to return to RxLink. This will allow
          your current pharmacy insurance coverage information to be compared with available discounts in real time.
        </p>
        {loading &&
          <div style={{ zIndex: 1001, position: "absolute", right: isPhone || isTab ? "50%" : "31%" }}><Spinner animation="border" variant="primary" /></div>
        }
        <p style={{ color: checkboxError ? "red" : "" }}>
          Please have your username and password for your insurer's website ready when you click "submit".
        </p>
      </div>

      <div className="hcdl-buttons">
        <button
          className="btn_success"
          type="button"
          onClick={() => submitHCDL()}
        // disabled={!checked}
        >
          Submit
        </button>
        <button
          className="btn_default"
          type="button"
          onClick={() => onSkip()}
        >
          Skip
        </button>
      </div>
    </div>
  )
}