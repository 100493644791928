import { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MotionIcon } from "./motion/MotionButton";
import { Divider } from "./searchPharmacy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faGreaterThan from "../assets/images/RxLink assets/new assets/arrow/side.svg"
// import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { setShowPahrmacyMap, setResetPwdModal, setFromDrawer, setShowCoupon, setPharmacyList } from "../reducers/medication";
import { httpClientJava } from "../environment";
import { motion } from "framer-motion";
import { MotionButton } from "./motion/MotionButton";
import { useGetUser } from "../utilities/useGetUser";

const Drawer = ({ totalSavings }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = JSON.parse(localStorage.getItem("user"));

  const user = useGetUser();

  const [name, setName] = useState("");

  const pharmacyList = useSelector((state) => state.medication.pharmacyList);
  const userPharmacy = user?.pharmacy;


  useEffect(() => {
    if (pharmacyList.length == 0 && userPharmacy?.userPharmacySeqNo) {
      dispatch(setPharmacyList([userPharmacy]))
    }
  }, [userPharmacy])

  const selectedPharmacy = pharmacyList.length > 0 ? pharmacyList.filter(item => !!item.isDefault) : [];

  const savings = useSelector((state) => state.medication.savingHistory);
  const insuranceInfo = useSelector((state) => state.global.insuranceInfo);

  useEffect(() => {
    if (insuranceInfo || user) {
      if (user && user?.firstName && user.firstName != "" && user.firstName != " ") setName(user.firstName);
      else if (insuranceInfo && insuranceInfo?.firstName && insuranceInfo?.firstName != "") setName(insuranceInfo?.firstName);
      else if (user && user?.middleName && user.middleName != "" && user.firstName != " ") setName(insuranceInfo?.middleName)
      else if (insuranceInfo && insuranceInfo?.middleName && insuranceInfo?.middleName != "") setName(insuranceInfo?.middleName)
      else if (user && user?.lastName && user.lastName != "") setName(insuranceInfo?.lastName && user.lastName != " ")
      else if (insuranceInfo && insuranceInfo?.lastName && insuranceInfo?.lastName != "") setName(insuranceInfo?.lastName)
    }
  }, [insuranceInfo, user])


  const logout = async () => {
    dispatch(setShowCoupon(false))
    navigate("/");
    await httpClientJava
      .post("patient/logout", { appUserSeqNo: user.appUserSeqNo })
      .then((response) => {
      })
      .catch((err) => console.log({ err }))
    localStorage.clear();
    sessionStorage.clear();
  }

  return (
    // aria-labelledby="offcanvasRightLabel"
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" >
      <div className="offcanvas-header">
        <div className="medicine_icon pharma_icon back_icon my-4 mx-0"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <a href="javascript:void(0)" className="txtDecoration"><i aria-label="Close my account section" className="fas fa-angle-right"></i></a>
        </div>
      </div>
      <div className="offcanvas-body">
        <div className="right-aligned-item"> <span>Hello {name}</span> </div>
        {totalSavings > 0 && <div className="right-aligned-item" > <span>You have saved <strong>$</strong> <span className="bold-text">{totalSavings.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</span></span> </div>}
        <Divider />
        <div style={{ margin: "18px 0px 0px 0px" }}>
          <DrawerItem title="My account" iconToRender={faGreaterThan} onClickHandler={() => navigate("/myAccount")} />
          <DrawerItem
            title={selectedPharmacy.length > 0 ? selectedPharmacy[0]["pharmacyName"] : "My Pharmacy"}
            iconToRender={faGreaterThan} subText={selectedPharmacy.length > 0 ? selectedPharmacy[0]["address"] : ""}
            onClickHandler={() => {
              navigate("/medications");

              dispatch(setShowPahrmacyMap(true))
              dispatch(setShowCoupon(false))
              dispatch(setFromDrawer(true))
              // if (isCoupon) 
            }} />
          <DrawerItem title="Savings History" iconToRender={faGreaterThan} onClickHandler={() => navigate("/savings")} />
          <DrawerItem title="Reset Password" iconToRender={faGreaterThan} onClickHandler={() => {
            dispatch(setResetPwdModal(true))
          }} />
        </div>
        <div className="logout-item">
          <DrawerItem title="Sign out" onClickHandler={logout} />
        </div>
      </div>
    </div>
  )
}

export default Drawer;


const DrawerItem = ({ title, iconToRender, subText, onClickHandler }) => {

  const ref = useRef(null);

  useLayoutEffect(() => {
    ref.current.style.setProperty("max-width", "100%", "important");
  }, []);

  return (
    <>
    {/* <MotionButton> */}
      <button className="btn_default text-start"
        ref={ref}
        onClick={() => {
          if (!!onClickHandler) {
            onClickHandler();
          }
        }}
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        whileTap={{ transition: { type: "tween", duration: 0.1 }, scale: 0.95 }}
      >
        <div className="row">
          <span className="col-10 fw-bold">
            {title}
            {!!subText && <br />} {!!subText && <span style={{ fontSize: 12 }} className="fw-normal">{subText}</span>}
          </span>
          {!!iconToRender && <span className="col-2 icon-forward"
          >
            <i aria-label={"open " + title}>
              <img src={faGreaterThan} alt="forward" />
            </i>
          </span>}
        </div>
      </button>
    {/* </MotionButton> */}
    </>
  )
}