import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMedication, setMedication } from "../../reducers/medication";
import { fetchDrugsPrices } from "../../reducers/drugPrices";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from "react-router-dom";
import editPencil from "../../assets/images/RxLink assets/new assets/edit pen.svg"
import { fetchMedicationList } from "../../reducers/javaMedications";
import { deleteMedication } from "../../reducers/removeMedication";
import { addOrUpdateMedication } from "../../reducers/saveOrUpdateMedication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import editIcon from "../../assets/images/RxLink assets/new assets/edit pen.svg";
import PopupCouponCard from "../coupan/PopupCouponCard";
// import { membersAssign, createRxSenseCard, setRxSenseCardInfo, setHippoCardInfo } from "../../reducers/assignMembers";
import { setSavedNpi, setTriggerModelFromCoupon, setPharmaInfo } from "../../reducers/medication";
import bestOffer from "../../assets/images/best-offer.png";
import cheaper from "../../assets/images/cheaper.png";
import arrowUp from '../../assets/images/RxLink assets/new assets/arrow/up.svg'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {
  AnimatePresence,
  motion,
  useAnimation,
  useAnimationControls,
} from "framer-motion";
import { wrapperVariants } from "../animationsVariants";
import { MotionIcon } from "../motion/MotionButton";
import { store } from "../../store";

import { setCouponData, setShowCoupon } from "../../reducers/medication";
import { searchPharmacyLogo } from "../PharmacyLogo";

import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
import { getCrxBestPrice } from "../../utilities/GetPrice";
import ariaAnnounce from "../../utilities/useAnnounceText";
import { useGetUser } from "../../utilities/useGetUser";
import { normalizeCompanyName } from "../../utilities/getNormalizedName";


const InitObject = {
  variant_id: 0,
  quantity: 0,
  package_size: 0,
  drugPrices: [],
};

const RightReviewCoupon = forwardRef(({
  medParam,
  valParam,
  setSavingOptionsPram,
  savingOptionParam,
  refParam,
  medDrugData,
  ownPosition,
  // pharmacyInfo,
  pricesList,
  medicationInfo,
  bestPackage,
  insuranceDetail
}, ref) => {
  const [medications, setMedications] = useState([]);
  const [tempDrugsPrices, setTempDrugsPrices] = useState([]);
  const [finalDrugsPrices, setFinalDrugsPrices] = useState([]);
  const [tempSelectedObj, setTempSelectedObj] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [label, setLabel] = useState("");
  const [form, setForm] = useState("");
  const [dosage, setDosage] = useState("");
  const [type, setType] = useState("");
  const [drugPrices, setDrugPrices] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewSavingOptions, setSavingOptions] = useState(false);
  const [tempAllMedDrugsPrices, setTempAllMedDrugsPrices] = useState();
  const [tempAllMedDrugsPricesSelect, setTempAllMedDrugsPricesSelect] =
    useState();
  const [selectMapValues, setSelectMapValues] = useState([]);
  const [savingPercentage, setSavingPercentage] = useState(0);
  const [objPopupCoupon, setObjPopupCoupon] = useState({});
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [isSaveValueForCoupon, setSaveValueForCoupon] = useState({});
  const userDetail = useGetUser();
  // var userDetail = JSON.parse(localStorage.getItem("user"));
  const [memberAssign, setMemberAssign] = useState({});
  const [rxSenseCard, setRxSenseCard] = useState({});

  const [isShowPencilDiv, setShowPencilDiv] = useState(false);
  const [pharmacyInfo, setPharmacyInfo] = useState({});
  const [cheaperOptionAvailable, setCheaperOptionAvailable] = useState(false);
  const controlsAnimation = useAnimationControls();

  const reduxState = store.getState();
  const rxSenseCardInfo = reduxState.membersAssignAPI.rxSenseCardInfo;
  const hippoCardInfo = reduxState.membersAssignAPI.hippoCardInfo;

  const pharmacyList = useSelector((state) => state.medication.pharmacyList);
  const selectedPharmacy = pharmacyList?.length > 0 ? pharmacyList.filter(item => !!item.isDefault) : [];
  const tooltipOfferRef = useRef(null);

  const controls = useAnimation();

  const { windowWidth } = useWindowWidth();
  const regex = new RegExp('pharmacy', 'gi');

  // const cheaperOptionAvailable = bestPackage.some(
  //   (option) => option.bestPrice < pharmacyInfo?.bestPrice
  // );

  if (cheaperOptionAvailable) {
    console.log("A cheaper option is available.");
  } else {
    console.log("The selected option is the cheapest.");
  }
  useEffect(() => {
    if (bestPackage?.length > 0) {
      let selectedPharmacy = bestPackage?.filter((x, i) => normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.chain) || normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.pharmacyName)?.toLowerCase());
      if(selectedPharmacy.length == 0){
        let local =  bestPackage?.filter((item)=> item?.chain == "local");
        if(local.length > 0){
          selectedPharmacy = local?.[0]?.pharmaciesList?.filter((x, i) => normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.chain) || normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.pharmacyName)?.toLowerCase());
        }
      }
      setPharmacyInfo(selectedPharmacy[0]);
      dispatch(setPharmaInfo(selectedPharmacy[0]))
    }
    else if (bestPackage?.length === 0) {
      setPharmacyInfo({});
      dispatch(setPharmaInfo({}))

    }

  }, [bestPackage])

  useEffect(() => {
    let cheaperOption = bestPackage.some(
      (option) => option.bestPrice < pharmacyInfo?.bestPrice
    );
    if (bestPackage?.length > 0 && !pharmacyInfo) {
      setCheaperOptionAvailable(true);
    }
    else {
      setCheaperOptionAvailable(cheaperOption);
    }
  }, [pharmacyInfo])

  useEffect(() => {
    // console.log({valParam});
    let priceToAdd = 0;
    let priceToMinus = 0;
    pharmacyInfo?.drugs?.map((dr) => {
      if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
        priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
        priceToMinus += dr.bestPrice;
      }
    })
    let newSum = pharmacyInfo?.bestPrice - priceToMinus + priceToAdd;
    let costPercentage = 0;
    costPercentage = (newSum / pharmacyInfo?.estRetailPrice) * 100;
    let percentage = 100 - costPercentage;
    setSavingPercentage(percentage);
  }, [pharmacyInfo]);

  useImperativeHandle(ref, () => ({
    couponModelToCouponScreen() {
      if (isOpenCouponModal) {
        setIsOpenCouponModal(false);
        dispatch(setSavedNpi(valParam.npi))
        savingMap(pharmacyInfo, medParam, savingPercentage)
      }
    },
    couponScreenToCouponModel() {
      dispatch(setTriggerModelFromCoupon(false))
      savingMapTab(pharmacyInfo, medParam, savingPercentage)
    },
  }));

  const savingMap = (sub_element, pharmacyDrugsInfo) => {
    let obj = {
      drug: sub_element,
      pharmacyDrugsInfo: pharmacyDrugsInfo,
      savingPercentage: savingPercentage,
      memberAssign: memberAssign,
      isPharmacyInsShow: false,
      medDrugData: medDrugData,
      cheaperOptionAvailable: cheaperOptionAvailable
    };
    setSavingOptions(false);
    setSavingOptionsPram(false);
    dispatch(setCouponData(obj));
    dispatch(setShowCoupon(true));
    // navigate("/coupon", { state: obj });
  };

  const savingMapTab = (sub_element, pharmacyDrugsInfo) => {
    let obj = {
      drug: sub_element,
      pharmacyDrugsInfo: pharmacyDrugsInfo,
      savingPercentage: savingPercentage,
      memberAssign: memberAssign,
      isPharmacyInsShow: false,
      medDrugData: medDrugData,
    };
    setSaveValueForCoupon(obj);
    setIsOpenCouponModal(true);
    dispatch(setCouponData(obj));
    setObjPopupCoupon(obj);
    setSavingOptions(false);
    setSavingOptionsPram(false);
  };

  const navigateToCostPlusDrug = () => {
    //navigate();
    window.open('https://costplusdrugs.com/create-account/', "_blank");
  }

  useEffect(() => {
    setSavingOptions(savingOptionParam);
    // on select rightReview coupon close
    // setShowPencilDiv(false);
  }, [savingOptionParam]);

  const viewOptions = () => {
    if (viewSavingOptions) {
      setSavingOptions(false);
      setSavingOptionsPram(false);
    } else {
      setSavingOptions(true);
      setSavingOptionsPram(true);
    }
    // refParam?.current?.scrollTo({ top: 1000, behavior: 'smooth' });
  };

  const navigationToCoupon = () => {
    setIsOpenCouponModal(false);
    navigate("/coupon", { state: isSaveValueForCoupon });
  };

  const hideCoupon = () => {
    setIsOpenCouponModal(false);
  };

  const handlePencilAction = () => {
    setIsOpenCouponModal(false);
    setSavingOptions(true);
    setSavingOptionsPram(true);
    window.scroll(0, 0);
  };

  // useEffect(() => {
  //   const membersAssignData = JSON.parse(localStorage.getItem("membersAssign"));
  //   if (membersAssignData) {
  //     setMemberAssign(membersAssignData);
  //     dispatch(setHippoCardInfo(membersAssignData));
  //     return;
  //   }
  //   let body = {
  //     appUserSeqNo: userDetail?.appUserSeqNo,
  //   };
  //   //let temp = "{\"member_id\":\"RKM7JDPFWW\", \"group_id\":\"002\", \"pcn\":\"CHIPPO\", \"bin\":\"019876\", \"phone\":\"\", \"phone_active\":true, \"email\":\"\", \"email_active\":true, \"attributes\":{}, \"links\":[{\"rel\":\"member_card_image\", \"href\":\"https://card.hippo.tools/image.png?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_website\", \"href\":\"https://hellohippo.com/add-to-wallet?member_id=RKM7JDPFWW\"}, {\"rel\":\"member_card_apple\", \"href\":\"https://wallet.hippo.tools?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_google\", \"href\":\"https://gwallet.hippo.tools/pass/redirect?member_id=RKM7JDPFWW\"}]}"
  //   //setMemberAssign(JSON.parse(temp));
  //   //console.log(JSON.parse(temp));
  //   setLoading(true);
  //   dispatch(membersAssign(body))
  //     .unwrap()
  //     .then((response) => {
  //       setLoading(false);
  //       // if (response?.data) {
  //       setMemberAssign(JSON.parse(response?.data));
  //       dispatch(setHippoCardInfo(JSON.parse(response?.data)));
  //       localStorage.setItem("membersAssign", response?.data);
  //       // }
  //     })
  //     .catch((e) => {
  //       // console.log(e);
  //       setLoading(false);
  //     });
  // }, []);

  // useEffect(() => {
  //   const rxSenseCardData = JSON.parse(localStorage.getItem("rxSenseCard"));
  //   if (rxSenseCardData) {
  //     // setRxSenseCard(rxSenseCardData);
  //     dispatch(setRxSenseCardInfo(rxSenseCardData))


  //     return;
  //   }
  //   let body = {
  //     appUserSeqNo: userDetail?.appUserSeqNo,
  //   };
  //   setLoading(true);
  //   dispatch(createRxSenseCard(body))
  //     .unwrap()
  //     .then((response) => {
  //       // if (response.data) {
  //       dispatch(setRxSenseCardInfo(response.data))
  //       // }
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    if (rxSenseCardInfo?.bin) {
      localStorage.setItem("rxSenseCard", JSON.stringify(rxSenseCardInfo));
    }
  }, [rxSenseCardInfo])

  const showHidePencil = () => {
    if (isShowPencilDiv) {
      setShowPencilDiv(false);
      controlsAnimation.stop({ y: 100, transition: { duration: 0.5 } });
    } else {
      setShowPencilDiv(true);
      controlsAnimation.start({ y: 100, transition: { duration: 0.5 } });
    }
  };
  const variants = {
    open: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    closed: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };
  const init = isShowPencilDiv ? { y: 100 } : { y: -155 };

  console.log({ rxSenseCardInfo, hippoCardInfo, pharmacyInfo })

  return (
    <>
      <section className="couponTabFix">

        <>
          <AnimatePresence>
            {medDrugData?.length > 0 && (<>


              <motion.div
                className="col-sm-12 col-md-12 col-lg-12 couponMobFixed"
                controls={controlsAnimation}
                layout
                key={isShowPencilDiv ? "open" : "closed"}
                animate={isShowPencilDiv ? "open" : "closed"}
                variants={variants}
                initial={init}
              // style={{position: "relative"}}
              // exit={{y:100, transition:{ type: "tween", duration: 0.3}}}
              >
                <motion.div
                  className="medications_inner medications_inner_coupon dlr-Sup mt-0 mb-0 pos_rel"
                // initial={{ y: 10 }} animate={{ y: 0 }} transition={{ duration: 0.23, opacity: 0, type: "tween" }} exit={{y :150, transition:{ duration: 1, type: "tween"}}}
                >
                  <motion.div className="row mobileView-tabView ">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="angleUpMain ">

                        <div className=" angleUp">
                          {/* <MotionIcon> */}
                          <motion.i
                            initial={{ rotate: !isShowPencilDiv ? 180 : 0 }}
                            animate={{ rotate: !isShowPencilDiv ? 0 : 180 }}
                            transition={{ duration: 0.3 }}
                          >  <a href="javascript:void(0)" onClick={() => showHidePencil()}>
                              <img src={arrowUp} alt="See pharmacy information" /></a>
                          </motion.i>
                          {/* </MotionIcon> */}
                        </div>

                      </div>
                    </div>
                  </motion.div>
                  <motion.div className="row">
                    <div className="col-7 col-sm-7 col-md-8 col-lg-12">
                      {(pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance )? (<>
                        <h2 className="totalPocket" aria-label="TOTAL OUT OF POCKET">TOTAL OUT OF POCKET</h2>
                        <motion.div className="med-Flex" tabIndex={0} >
                          <span style={{ fontSize: 27, lineHeight: 1.3, fontWeight: "bold" }} className="webView div-hidden" aria-label={"$" + getCrxBestPrice(pharmacyInfo) + " " + Math.round(savingPercentage) + "% off"}>
                            <sup>
                              <b>$&nbsp;</b>
                            </sup>
                            {
                              // pharmacyInfo?.bestPrice?.toLocaleString(undefined, {
                              //   minimumFractionDigits: 2,
                              //   maximumFractionDigits: 2
                              // })
                              getCrxBestPrice(pharmacyInfo, true)
                            }{" "}
                          </span>
                          <span style={{ fontSize: 27, lineHeight: 1.3, fontWeight: "bold" }} className="tabView" aria-label={"$" + getCrxBestPrice(pharmacyInfo) + " " + Math.round(savingPercentage) + "% off"}>
                            <sup style={{ fontSize: 11 }}>
                              <b>$&nbsp;</b>
                            </sup>
                            {
                              // pharmacyInfo?.bestPrice?.toLocaleString(undefined, {
                              //   minimumFractionDigits: 2,
                              //   maximumFractionDigits: 2
                              // })
                              getCrxBestPrice(pharmacyInfo, true)
                            }{" "}
                          </span>
                          <span className="mobileView" style={{ display: "flex", fontSize: 20, fontWeight: "bold", lineHeight: 1.2 }} aria-label={"$" + getCrxBestPrice(pharmacyInfo) + " " + Math.round(savingPercentage) + "% off"}>
                            <sup style={{ paddingTop: 10, fontSize: 11 }}>
                              <b>$&nbsp;</b>
                            </sup>
                            {
                              // pharmacyInfo?.bestPrice?.toLocaleString(undefined, {
                              //   minimumFractionDigits: 2,
                              //   maximumFractionDigits: 2
                              // })
                              getCrxBestPrice(pharmacyInfo, true)
                            }{" "}
                          </span>
                          {savingPercentage > 0 && (
                            <p className="font13 flex-end" aria-hidden={true}>
                              {Math.round(savingPercentage)}%&nbsp;off{" "}
                            </p>
                          )}
                        </motion.div>
                      </>) : (<>Not Available</>)}

                      {/* {!medParam.length > 0 && (
                            <h1 className="mt-2">
                              <sup>
                                <b>$ </b>
                              </sup>
                              0
                            </h1>
                          )} */}



                      {/* {medParam.length > 0 &&
                      cheaperOptionAvailable ? <img
                        src={cheaper}
                        style={{height: 24, width: 160}}
                      /> :medParam.length > 0 ? <img
                        src={bestOffer}
                        style={{height: 24}} 
                      /> : null
                    } */}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 webView div-hidden copun_pharmacy">
                      {/* <motion.label className="hrDiv">PHARMACY</motion.label> */}
                      <h3 className="hrDiv totalPocket ">PHARMACY</h3>

                      <motion.div className="review-coupon ">
                        <div className="row ">
                          <div className="col-sm-3 col-md-3 col-lg-3 autoMargin">
                            <div className="pharmacyImg_div">
                              <img
                                src={searchPharmacyLogo(pharmacyInfo?.pharmacyName, pharmacyInfo?.logo?.small, pharmacyInfo?.chain?.toLowerCase())}
                                className="img-fluid"
                                alt={pharmacyInfo?.pharmacyName + ' Logo'}
                              // style={{maxWidth: 60}}
                              />
                            </div>
                          </div>
                          <div className="col-sm-7 col-md-7 col-lg-7 p-0 autoMargin">
                            <div className="" style={{ textAlign: "left" }}>
                              <p className="shopName">{((pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && pharmacyInfo?.pharmacyName) ? pharmacyInfo?.pharmacyName : selectedPharmacy?.length > 0 ? selectedPharmacy[0]["pharmacyName"] : ""}</p>
                            </div>
                          </div>
                          {/* <div className="col-sm-4 col-md-4 col-lg-3" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            {bestPackage.length > 0 &&
                              cheaperOptionAvailable ? <img src={cheaper} style={{ width: 109, paddingTop: 6, marginLeft: -15, height: 46 }} />
                              : bestPackage.length > 0 ? <img
                                src={bestOffer}
                                style={{ paddingTop: 4, height: 44 }}
                              /> : null
                            }
                          </div> */}
                          {/* <div className="col-sm-3 col-md-3 col-lg-3 best-cheap-offer" >
                            <OverlayTrigger
                              delay={{ hide: 100, show: 100 }}
                              trigger="hover"
                              ref={tooltipOfferRef}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {cheaperOptionAvailable ? "Cheaper Offer Available" : "Best Offer"}
                                </Tooltip>
                              )}
                              placement="auto"
                            >
                              {
                                cheaperOptionAvailable ? <img src={cheaper} style={{ cursor: "pointer" }} />
                                  : <img
                                    src={bestOffer}
                                    style={{ cursor: "pointer" }}
                                  />
                              }
                            </OverlayTrigger>
                          </div> */}

                          <div className="col-sm-2 col-md-2 col-lg-2 cursor-pointer edit-Icon autoMargin text-end">

                            {/* <MotionIcon> */}
                            <a href="javascript:void(0)" onClick={() => viewOptions()}>
                              <img className="" src={editPencil} alt="Change Pharmacy" />
                            </a>
                            {/* </MotionIcon> */}

                          </div>

                        </div>
                      </motion.div>

                    </div>
                    {/* pharmacyInfo?.cost */}

                    {/* <motion.div
                      className="col-5 col-sm-5 col-md-4 col-lg-12 autoMargin"
                      transition={{ type: "tween" }}
                      // whileHover={{
                      //   scale: 1.04,
                      //   transition: { duration: 0.3 },
                      //   opacity: 1,
                      // }}
                      whileTap={{ scale: 0.95 }}
                    > */}
                    <div
                      className="col-5 col-sm-5 col-md-4 col-lg-12 autoMargin"
                      transition={{ type: "tween" }}
                      // whileHover={{
                      //   scale: 1.04,
                      //   transition: { duration: 0.3 },
                      //   opacity: 1,
                      // }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {(pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && (<>
                        <button
                          className="btn_success webView div-hidden review-coupon-button mt-2"
                          // disabled={(!pharmacyInfo?.bestPrice || pharmacyInfo?.chain == undefined)}
                          onClick={() => {
                            ariaAnnounce("My medication savings")
                            dispatch(setSavedNpi(valParam.npi));
                            savingMap(pharmacyInfo, bestPackage, savingPercentage);
                          }
                          }
                        >
                          Review coupons
                        </button>
                        {/* Mobile btn */}
                        <button
                          className="btn_success mobileView seeCoupon"
                          // disabled={(!pharmacyInfo?.bestPrice || pharmacyInfo?.chain == undefined)}
                          onClick={() =>
                            savingMapTab(pharmacyInfo, bestPackage, savingPercentage)
                          }
                        >
                          See coupons
                        </button>
                        {/* tab btn */}
                        <button
                          className="btn_success tabView seeCoupon"
                          // disabled={!pharmacyInfo?.bestPrice}
                          onClick={() =>
                            savingMapTab(pharmacyInfo, bestPackage, savingPercentage)
                          }
                        >
                          See coupons
                        </button>
                      </>)}

                    </div>
                    {/* </motion.div> */}

                  </motion.div>
                  <AnimatePresence>
                    {isShowPencilDiv && (
                      <motion.div
                        className="row mobileView-tabView"
                        initial={{ y: "100%" }}
                        animate={{ y: isShowPencilDiv ? 0 : "100%" }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: "100%", transition: { duration: 0.5 } }}
                        layout
                        key={`${isShowPencilDiv}`}
                      //style={{position: "relative"}}
                      //initial={false} animate={{y: 0}} transition={{duration: 0.5, ease: "easeInOut"}} exit={{ y: 0, transition: { duration: 0.5, ease: "easeInOut" } }}
                      // initial={{ y: 100 }} exit={{ y: 75, transition: { duration: 0.5, ease: "easeInOut" } }} animate={{ y: 0 }} transition={{ duration: .3, type: "tween" }}
                      >
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                          <motion.label
                            className="hrDiv"
                            initial={{
                              marginTop: "0px",
                              paddingTop: "0px",
                              width: "0%",
                            }}
                            animate={{
                              width: "100%",
                              paddingTop: "20px",
                              marginTop: "10px",
                            }}
                            exit={{
                              marginTop: "0px",
                              paddingTop: "0px",
                              width: "0%",
                              transition: { duration: 0.5, ease: "easeInOut" },
                            }}
                            transition={{ duration: 0.5, type: "tween" }}
                          >
                            PHARMACY
                          </motion.label>

                          <div className="review-coupon ">
                            <div className="row ">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                <div className="pharmacyImg_div">
                                  <img
                                    src={searchPharmacyLogo(pharmacyInfo?.pharmacyName, pharmacyInfo?.logo?.small, pharmacyInfo?.chain?.toLowerCase())}
                                    className="img-fluid"
                                    alt={pharmacyInfo?.pharmacyName + ' Logo'}
                                  // style={{maxWidth: 60}}
                                  />
                                </div>
                              </div>
                              <div className="col-7 col-sm-7 col-md-7 col-lg-7 p-0 autoMargin">
                                <div className="">
                                  <p className="shopName" style={{ textAlign: "left" }}>
                                    {((pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && pharmacyInfo?.pharmacyName) ? pharmacyInfo?.pharmacyName: ""}
                                  </p>
                                  {/* <span>313 Canal St, NY</span> */}
                                </div>
                              </div>
                              {/* <div className="col-3 col-sm-3 col-md-3 col-lg-3" style={{ paddingLeft: 0, paddingRight: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
            
                                {bestPackage.length > 0 &&
                                  cheaperOptionAvailable ? <img src={cheaper} style={{ width: 109, height: 46 }} />
                                  : bestPackage.length > 0 ? <img
                                    src={bestOffer}
                                    style={{ paddingTop: 4, height: 44 }}
                                  /> : null
                                }
                              </div> */}
                              {/* <div className="col-3 col-sm-3 col-md-3 col-lg-3 best-cheap-offer" >
                                <OverlayTrigger
                                  delay={{ hide: 100, show: 100 }}
                                  trigger="hover"
                                  ref={tooltipOfferRef}
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {cheaperOptionAvailable ? "Cheaper Offer Available" : "Best Offer"}
                                    </Tooltip>
                                  )}
                                  placement="auto"
                                >
                                  {
                                    cheaperOptionAvailable ? <img src={cheaper} style={{ cursor: "pointer" }} />
                                      : <img
                                        src={bestOffer}
                                        style={{ cursor: "pointer" }}
                                      />
                                  }
                                </OverlayTrigger>
                              </div> */}

                              <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-end">
                                <a href="javascript:void(0)" onClick={() => viewOptions()}>
                                  <i
                                    transition={{ type: "tween" }}
                                    whileHover={{
                                      scale: 1.2,
                                      transition: { duration: 0.2 },
                                      opacity: 1,
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className="fa fa-pencil pointer"
                                    aria-label="Edit"
                                    alt="Edit"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>

                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
                {windowWidth > 991 &&
                  <>
                    {cheaperOptionAvailable ?
                      <div className="offer-section">
                        <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-circle-exclamation" style={{ fontSize: 21 }}></i><span>Your price may be lower at <a href="javascript:void(0)" onClick={() => viewOptions()}>a different pharmacy.</a></span>
                        </div>
                      </div>
                      :
                      <>
                        {(pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance || pharmacyInfo?.chain) && (
                          <div className="offer-section">
                            <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-tag" style={{ color: "#40DFB9", fontSize: 26 }}></i><span>Congratulations! Your pharmacy has the lowest total price for your drugs in your area!</span>
                            </div>
                          </div>
                        )}
                      </>
                    }
                  </>

                }
              </motion.div>
            </>)}
          </AnimatePresence>
        </>

      </section>
      {true &&
        <div className="col-sm-12 col-md-12 col-lg-3 couponMobFixed">
          <PopupCouponCard
            show={isOpenCouponModal}
            objParam={objPopupCoupon}
            close={() => hideCoupon()}
            navToCoupon={() => navigationToCoupon()}
            pencilAction={() => handlePencilAction()}
            drugPrices={medDrugData}
            ownPosition={ownPosition}
            cheaperOptionAvailable={cheaperOptionAvailable}
            windowWidth={windowWidth}
            insuranceDetail={insuranceDetail}
            originalFilter={valParam}
            setMemberAssign={setMemberAssign}
          />
        </div>
      }
    </>
  );
});

export default RightReviewCoupon;
