import { useEffect, useState } from "react";
import wallet from "../../assets/images/wallet.png";
import walletGoogle from "../../assets/images/g-wallet.png";
import InputMask from "react-input-mask";
import validator from "validator";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { membersAssign } from "../../reducers/assignMembers";
import Modal from "react-bootstrap/Modal";
import { sndSMSOrEmail } from "../../reducers/sendSMSOrEmail";
import { appleWalletAPI } from "../../reducers/appleWallet";
import { setSMSOrEmailResponse } from "../../reducers/sendSMSOrEmail";
import 'react-toastify/dist/ReactToastify.css';
import Header from "../partials/Header";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../toast/toast";

import {
  isBrowser,
  isMobile,
  isIOS,
  isDesktop,
  isAndroid,
  isMacOs,
} from "react-device-detect";
import smallImage from "../../assets/images/smallimg.png";
import { MotionButton, MotionIcon } from "../motion/MotionButton";
import LoadingComponent from "../LoadingComponent";

export default (props) => {
  const [pharmacistPara, setPharmacistPara] = useState(false);
  const [url, setUrl] = useState("");
  const [isVisibleSMSModal, setIsVisibleSMSModal] = useState(false);
  const [isVisibleEmailInput, setIsVisibleEmailInput] = useState(false);
  const [isVisibleEmailModal, setIsVisibleEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { state } = useLocation();
  // console.log(state);
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();
  var userDetail = JSON.parse(localStorage.getItem("user"));
  const [memberAssign, setMemberAssign] = useState("");
  const [isComingSoon, setIsComingSoon] = useState(false);
  const memberInfo = useSelector((state) => state.sendSMSOrEmailAPI.memberInfo);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    let body = {
      appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
      pharmacyName: state?.drug?.pharmacyName,
      price: "$" + state?.drug?.cost,
      drug: {
        name: state?.medications[0]?.selectedDrug?.name,
        dosage: state?.medications[0]?.selectedDrug?.dosage,
        form: state?.medications[0]?.selectedDrug?.form,
        default_quantity: state?.medications[0]?.selectedDrug?.default_quantity,
      },
      memberInfo: memberInfo ? memberInfo : null,
    };
    dispatch(appleWalletAPI(body))
      .unwrap()
      .then((response) => {
        setLoading(false);

        if (response?.status === "OK") {
          // addToast(response?.message, {appearance: "success",autoDismiss: 5000,});
          dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
          localStorage.setItem(
            "memberInfo",
            JSON.stringify(response?.data?.memberInfo)
          );
          //  const walletUrl = response?.data?.walletUrl;
          //console.log(response?.data?.walletUrl);
          setUrl(response?.data?.walletUrl);
          //  window.open(walletUrl, '_blank')?.focus();
        } else {
          // addToast(response?.message, {
          //   appearance: "error",
          //   autoDismiss: 5000,
          // });
          errorToast(response?.message)
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmail(e.target.value);
    if (validator.isEmail(email)) {
      setEmailError("");
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      setEmailError("Enter valid Email!");
    }
  };

  // const onChangePhoneNo = (e) => {
  //     setPhoneNo(e.target.value);
  // }
  // const onChangeIsVisiblePhoneNo = (e) => {
  //     setPhoneNo("");
  //     setIsVisiblePhoneNo(e);
  //     setIsVisibleSecondPhoneNo(false);
  //     setIsVisibleFirstEmail(false);
  //     setIsVisibleSecondEmail(false);
  // }
  // const onChangeSecondPhoneNo = (e) => {
  //     setPhoneNo("");
  //     setIsVisibleSecondPhoneNo(e);
  //     setIsVisiblePhoneNo(false);
  //     setIsVisibleFirstEmail(false);
  //     setIsVisibleSecondEmail(false);
  // }
  // const onChangeFirstEmail = (e) => {
  //     setPhoneNo("");
  //     setIsVisibleSecondPhoneNo(false);
  //     setIsVisiblePhoneNo(false);
  //     setIsVisibleFirstEmail(e);
  //     setIsVisibleSecondEmail(false);
  // }

  // const onChangeSecondEmail = (e) => {
  //     setPhoneNo("");
  //     setIsVisibleSecondEmail(e);
  //     setIsVisibleSecondPhoneNo(false);
  //     setIsVisiblePhoneNo(false);
  //     setIsVisibleFirstEmail(false);
  // }

  const sendSMS = (flag) => {
    if (flag) {
      setLoading(true);

      let body = {
        sendSMS: true,
        appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
        memberInfo: memberInfo ? memberInfo : null,
      };
      dispatch(sndSMSOrEmail(body))
        .unwrap()
        .then((response) => {
          setLoading(false);

          if (response?.status === "OK") {
            // addToast(response?.message, {
            //   appearance: "success",
            //   autoDismiss: 5000,
            // });
            successToast(response?.message);

            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            setIsVisibleSMSModal(false);
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
            // localStorage.setItem("smsWalletUrl",JSON.stringify(response?.data?.walletUrl));
            setUrl(response?.data?.walletUrl);
          } else {
            // addToast(response?.message, {
            //   appearance: "error",
            //   autoDismiss: 5000,
            // });
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
        });
    }
  };

  const sendEmail = (flag) => {
    if (flag) {
      setLoading(true);

      const drugArr = [];
      for (let index = 0; index < state?.medications.length; index++) {
        let tempBody = {
          name: state?.medications[index]?.selectedDrug?.name,
          dosage: state?.medications[index]?.selectedDrug?.dosage,
          form: state?.medications[index]?.selectedDrug?.form,
          default_quantity:
            state?.medications[index]?.selectedDrug?.default_quantity,
        };
        drugArr[index] = tempBody;
      }
      let body = {
        sendSMS: false,
        appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
        email: email,
        pharmacyName: state?.drug?.pharmacyName,
        price: "$" + state?.drug?.cost.toFixed(2),
        drugs: drugArr,
        memberInfo: memberInfo ? memberInfo : null,
      };
      dispatch(sndSMSOrEmail(body))
        .unwrap()
        .then((response) => {
          setLoading(false);

          if (response?.status === "OK") {
            // addToast(response?.message, {
            //   appearance: "success",
            //   autoDismiss: 5000,
            // });
            successToast(response?.message);
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            setIsVisibleEmailInput(false);
            setIsVisibleEmailModal(false);
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
          } else {
            // addToast(response?.message, {
            //   appearance: "error",
            //   autoDismiss: 5000,
            // });
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
        });
    }
  };

  const appleWallet = () => {
    if (JSON.parse(localStorage.getItem("smsWalletUrl"))) {
      const walletUrl = JSON.parse(localStorage.getItem("smsWalletUrl"));
      //  window.open(walletUrl, '_blank')?.focus();
    } else {
      setLoading(true);
      let body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
        pharmacyName: state?.drug?.pharmacyName,
        price: "$" + state?.drug?.cost,
        drug: {
          name: state?.medications[0]?.selectedDrug?.name,
          dosage: state?.medications[0]?.selectedDrug?.dosage,
          form: state?.medications[0]?.selectedDrug?.form,
          default_quantity:
            state?.medications[0]?.selectedDrug?.default_quantity,
        },
        memberInfo: memberInfo ? memberInfo : null,
      };
      dispatch(appleWalletAPI(body))
        .unwrap()
        .then((response) => {
          setLoading(false);

          if (response?.status === "OK") {
            // addToast(response?.message, {appearance: "success",autoDismiss: 5000,});
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
            var walletUrl = response?.data?.walletUrl;
            //console.log(walletUrl);
            //  window.open(walletUrl, '_blank')?.focus();
          } else {
            // addToast(response?.message, {
            //   appearance: "error",
            //   autoDismiss: 5000,
            // });
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
        });
    }
  };

  const IMHere = (e) => {
    setPharmacistPara(e);
    if (JSON.parse(localStorage.getItem("memberInfo"))) {
      setMemberAssign(JSON.parse(localStorage.getItem("memberInfo")));
    } else {
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      //let temp = "{\"member_id\":\"RKM7JDPFWW\", \"group_id\":\"002\", \"pcn\":\"CHIPPO\", \"bin\":\"019876\", \"phone\":\"\", \"phone_active\":true, \"email\":\"\", \"email_active\":true, \"attributes\":{}, \"links\":[{\"rel\":\"member_card_image\", \"href\":\"https://card.hippo.tools/image.png?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_website\", \"href\":\"https://hellohippo.com/add-to-wallet?member_id=RKM7JDPFWW\"}, {\"rel\":\"member_card_apple\", \"href\":\"https://wallet.hippo.tools?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_google\", \"href\":\"https://gwallet.hippo.tools/pass/redirect?member_id=RKM7JDPFWW\"}]}"
      //setMemberAssign(JSON.parse(temp));
      //console.log(JSON.parse(temp));
      setLoading(true);
      dispatch(membersAssign(body))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.data) {
            setMemberAssign(JSON.parse(response?.data));
          }
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
        });
    }
  };

  const coupon = () => {
    navigate("/coupon");
  };

  return (
    <>
      {loading ? (
        <div className="overlay_loading"><LoadingComponent/></div>

      ) : (
        <>
          <section className="coupon ">
            <div className="container">
              <div className="row ">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <a href='javascript:void(0)' onClick={() => coupon()}>
                    <div
                      className="medicine_icon pharma_icon back_icon"
                    >
                      <MotionIcon><i className="fas fa-angle-left" aria-label="Go Back"></i></MotionIcon>
                    </div>
                  </a>
                </div>
              </div>

              <div className="row  imhere_main">
                <div className="col-sm-12 col-md-12 col-lg-12 ">
                  <div className="medications_inner">
                    <div className="row ">
                      <div className="col-sm-12 col-md-12 col-lg-12" role="alert">
                        <h1>
                          <b>Instructions for pharmacy staff</b>
                        </h1>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="coupon_link">
                          1. for Vyvanse (lisdexamfetamine dimesylate)
                        </p>
                        <div className="container">
                          <div className="container">
                            {/* Use with */}
                            <div className="container">
                              <p className="normal">
                                Use patient's insurance card as primary payer:
                              </p>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="card_info_new">
                                  <div className="row">
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                      <h4>Vyvanse</h4>
                                      <h2>200mg 21 capsules</h2>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                      <h2>with copay assistance</h2>
                                      <h3>$75.00</h3>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="text-center">
                                        <span>current as of 10/12/2023</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="car_info_bottom_new">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="table-responsive text-center">
                                        <table className="table table-responsive">
                                          <thead>
                                            <tr>
                                              <th>PCN</th>
                                              <th>ID</th>
                                              <th>BIN</th>
                                              <th>GROUP</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>POR23</td>
                                              <td>POR23</td>
                                              <td>POR23</td>
                                              <td>POR23</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Use with */}
                            <div className="container">
                              <p className="normal">
                                Use with patient’s insurance
                              </p>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="card_info_new">
                                  <div className="row">
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                      <h4>Vyvanse</h4>
                                      <h2>200mg 21 capsules</h2>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                      <h2>with copay assistance</h2>
                                      <h3>$75.00</h3>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="text-center">
                                        <span>current as of 10/12/2023</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="car_info_bottom_new">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="table-responsive text-center">
                                        <table className="table table-responsive">
                                          <thead>
                                            <tr>
                                              <th>PCN</th>
                                              <th>ID</th>
                                              <th>BIN</th>
                                              <th>GROUP</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>POR23</td>
                                              <td>POR23</td>
                                              <td>POR23</td>
                                              <td>POR23</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* OLD */}
          {/* <section className="home_one">
            <div className="container">
              {pharmacistPara && (
                <div
                  onClick={() => IMHere(false)}
                  className="row"
                  id="btn-row-back"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <button className="btn btn-info" id="btnback">
                      Back
                    </button>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  {!pharmacistPara && (
                    <>
                      <div className="row" id="div-1">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="coupon_new_div_saving">
                            <div className="row">
                              <div className="col-12 ol-sm-12 col-md-12 col-lg-12">
                                <div className="coupon_new_div_saving_inner">
                                  <p className="drug_name">
                                    1. Save this offer for
                                    {state?.medications?.map(
                                      (element, index) => (
                                        <label>
                                          {state?.medications?.length > 0 &&
                                          index != 0
                                            ? index !=
                                              state?.medications?.length - 1
                                              ? "\xa0"
                                              : "\xa0and\xa0"
                                            : "\xa0"}
                                          {
                                            element?.medicationAPIRes
                                              ?.search_name
                                          }
                                          {index !=
                                            state?.medications?.length - 1 &&
                                          index !=
                                            state?.medications?.length - 2
                                            ? ","
                                            : ""}
                                        </label>
                                      )
                                    )}
                                  </p>
                                  <div className="text-center">
                                    <p className="drug_name_subheading">
                                      Use this card instead of your insurance to
                                      save $
                                      {(
                                        state?.drug?.estRetailPrice -
                                        state?.drug?.cost
                                      ).toFixed(2)}{" "}
                                      on
                                      {state?.medications?.map(
                                        (element, index) => (
                                          <label>
                                            {state?.medications?.length > 0 &&
                                            index != 0
                                              ? index !=
                                                state?.medications?.length - 1
                                                ? "\xa0"
                                                : "\xa0and\xa0"
                                              : "\xa0"}
                                            {
                                              element?.medicationAPIRes
                                                ?.search_name
                                            }
                                            {index !=
                                              state?.medications?.length - 1 &&
                                            index !=
                                              state?.medications?.length - 2
                                              ? ","
                                              : ""}
                                          </label>
                                        )
                                      )}
                                      <br />
                                      Your expected cost at{" "}
                                      {state?.drug?.pharmacyName} pharmacy: $
                                      {state?.drug?.cost.toFixed(2)}
                                    </p>
                                  </div>
                                  <div className="row">
                                    <div
                                      className={
                                        isIOS || isAndroid
                                          ? "col-3 col-sm-4 col-md-4 col-lg-4"
                                          : "col-6 col-sm-6 col-md-6 col-lg-6"
                                      }
                                      onClick={() => sendSMS(true)}
                                    >
                                      <div className="text-center">
                                        <button type="button" className="btn">
                                          Text
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        isIOS || isAndroid
                                          ? "col-3 col-sm-4 col-md-4 col-lg-4"
                                          : "col-6 col-sm-6 col-md-6 col-lg-6"
                                      }
                                      onClick={() =>
                                        setIsVisibleEmailInput(true)
                                      }
                                    >
                                      <div className="text-center">
                                        <button type="button" className="btn">
                                          Email
                                        </button>
                                      </div>
                                    </div>
                                    Apple wallet
                                    {isIOS && (
                                      <div className="col-6 col-sm-4 col-md-4 col-lg-4 ps-0">
                                        <div className="text-center">
                                          <a href={url}>
                                            <button className="btn apple_btn">
                                              <img
                                                src={wallet}
                                                className="img-fluid"
                                              />
                                            </button>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                   
                                    Google wallet
                                    {isAndroid && (
                                      <div className="col-6 col-sm-4 col-md-4 col-lg-4 ps-0">
                                        <div className="text-center">
                                          <a href={url}>
                                            <button className="btn apple_btn">
                                              <img
                                                src={walletGoogle}
                                                className="img-fluid"
                                              />
                                            </button>
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {isVisibleEmailInput && (
                                    <>
                                      <div className="insurance_inputfeild_new">
                                        <input
                                          type="text"
                                          className="form-control text_input_insurance"
                                          id="userEmail"
                                          placeholder="Enter email"
                                          onChange={(e) => validateEmail(e)}
                                        />
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color: "red",
                                          }}
                                        >
                                          {emailError}
                                        </span>
                                        <div className="text-end">
                                          <button
                                            className="btn btn-secondary turquoise_btn close_save_responsive"
                                            onClick={() =>
                                              setIsVisibleEmailInput(false)
                                            }
                                          >
                                            Close
                                          </button>
                                          <button
                                            className="btn btn-secondary turquoise_btn close_save_responsive"
                                            disabled={!isValidEmail}
                                            onClick={() => sendEmail(true)}
                                          >
                                            Send
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="coupon_new_div_saving_inner">
                                  <div className="text-center">
                                    <h5>
                                      2. When you get to the pharmacy click:
                                    </h5>
                                    <button
                                      className="btn"
                                      id="imhere"
                                      onClick={() => IMHere(true)}
                                    >
                                      <b>I'M HERE</b>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            New Div 
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="coupon_new_div_saving_inner">
                                  <div className="text-center">
                                    <h5>
                                      3. Congratulations! You saved $
                                      {(
                                        state?.drug?.estRetailPrice -
                                        state?.drug?.cost
                                      ).toFixed(2)}{" "}
                                      on all of your medications. To track your
                                      savings click:{" "}
                                    </h5>
                                    <button
                                      className="btn"
                                      id="imhere"
                                      onClick={() => setIsComingSoon(true)}
                                    >
                                      <b>Savings Report</b>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                 
                </div>
              </div>
            </div>
          </section> */}

          <Modal
            show={isVisibleSMSModal}
            onHide={() => setIsVisibleSMSModal(false)}
            keyboard={false}
            centered
          >
            {/* <Modal.Header closeButton>
                    <Modal.Title>SMS</Modal.Title>
                </Modal.Header> */}
            <Modal.Body>
              <section>
                <div className="container">
                  <form id="loginID">
                    <div className="forget_box">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p>Are you sure you want to active SMS</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          {/* <MotionButton> */}
                            <button
                              type="button"
                              className="btn btn-primary forget_btn"
                              onClick={() => sendSMS(true)}
                            >
                              Yes
                            </button>
                          {/* </MotionButton> */}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          {/* <MotionButton> */}
                            <button
                              type="button"
                              className="btn btn-primary forget_btn"
                              onClick={() => setIsVisibleSMSModal(false)}
                            >
                              No
                            </button>
                          {/* </MotionButton> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </Modal.Body>
            {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
          </Modal>

          <Modal
            show={isComingSoon}
            onHide={() => setIsComingSoon(false)}
            keyboard={false}
            centered
          >
            {/* <Modal.Header closeButton>
                    <Modal.Title>SMS</Modal.Title>
                </Modal.Header> */}
            <Modal.Body>
              <section>
                <div className="container">
                  <form id="loginID">
                    <div className="forget_box row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <p className="text-center">Coming soon!</p>
                      </div>
                      <div className="row">
                        <div className="center">
                          {/* <MotionButton> */}
                            <button
                              type="button"
                              className="btn btn-primary commingSoonBtn"
                              onClick={() => setIsComingSoon(false)}
                            >
                              OK
                            </button>
                          {/* </MotionButton> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </Modal.Body>
            {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
          </Modal>
        </>
      )}
    </>
  );
};
