import React, { useState, useEffect } from 'react';
import { DotLottiePlayer } from '@dotlottie/react-player';
import { useWindowWidth } from './searchPharmacy/useWindowWidth';
import { MotionTextH2, MotionTextHeading, MotionTextPara } from './motion/MotionText';
import ariaAnnounce from '../utilities/useAnnounceText';

const InfoSection = () => {
    const { windowWidth } = useWindowWidth();
    const isTab = windowWidth > 766 && windowWidth <= 992;
    const isDesktop = windowWidth > 992;
    const isMobile = (isTab == false && isDesktop == false);

    const [text1, setText1] = useState('Searching for prescription discounts');
    const [text2, setText2] = useState('discounts up a lot of savings');

    useEffect(() => {
        const interval1 = setInterval(() => {
            setText1((prevText) => (prevText === 'Searching for prescription discounts' ? 'Checking manufacturer savings offers' : 'Searching for prescription discounts'));
        }, 3000);

        const interval2 = setInterval(() => {
            setText2((prevText) => (prevText === 'discounts up a lot of savings' ? "so much savings we're off'er rockers" : 'discounts up a lot of savings'));
        }, 3000);

        return () => {
            clearInterval(interval1);
            clearInterval(interval2);
        };
    }, []);

    const getContainerStyle = () => {
        let containerStyle = {};

        if (isDesktop) {
            containerStyle = {
                position: "absolute",
                left: 80,
                top: 160,
                zIndex: 1,
                opacity: .8
            }
        }
        else if (isTab) {
            containerStyle = {
                // position: "absolute",
                // left: 100,
                // top: 120,
                // textAlign: "center"
                position: "fixed",
                left: "50%",
                top: "20%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                width: "max-content",
                zIndex: 1,
                opacity: .8
            }
        }
        else {
            containerStyle = {
                position: "fixed",
                left: "50%",
                top: "20%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                width: "max-content",
                zIndex: 1,
                opacity: .8
            }
        }
        return containerStyle;
    }

    const getHeadingStyle = () => {
        let headingStyle = {};
        if (isDesktop) {
            headingStyle = {
                fontWeight: 600,
                maxWidth: 489,
                fontSize: 40
            }
        }
        else if (isTab) {
            headingStyle = {
                fontWeight: 600,
                maxWidth: 520,
                fontSize: 42,
            }
        }
        else {
            headingStyle = {
                fontWeight: 600,
                maxWidth: 300,
                fontSize: 22
            }
        }
        return headingStyle
    }
    return (
        <div className='info-section-loader'
        style={getContainerStyle()}>
            <MotionTextHeading text={text1} style={getHeadingStyle()} key={text1} />
            <MotionTextPara text={text2} style={{ fontSize: 14 }} key={text2}/>
        </div>
    )
}

const Loader = () => {

    const { windowWidth } = useWindowWidth();
    const isTab = windowWidth > 766 && windowWidth <= 992;
    const isDesktop = windowWidth > 992;
    const isMobile = (isTab == false && isDesktop == false);

    const getMarginLeft = () => {
        if (isDesktop) return 200;
        else if (isTab) return -700;
        else return -1380
    }

    useEffect(()=>{
        ariaAnnounce("please wait")
    },[])

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            minWidth: '1800px',  // Set a minimum width
            // minHeight: '900px', // Set a minimum height
            zIndex: 100000,
            // backgroundImage: "url(../assets/images/web.png)", // Replace with the actual path to your image
            // backgroundSize: "cover", // Adjust as needed
            // backgroundRepeat: "no-repeat",
            background: 'linear-gradient(50deg, rgb(255, 255, 255) 0%, rgb(209 255 243) 100%)'

        }}

        >
            <InfoSection />

            <DotLottiePlayer
                src="https://lottie.host/4d4c51a7-31b3-42a4-b8aa-44940598c27d/5hMpldE7g5.json"
                background="transparent"
                speed={1}
                loop
                autoplay
                style={{
                    width: '100%',
                    height: '120%',
                    marginLeft: getMarginLeft()
                }}
            />
        </div>
    );
}

export default Loader;