import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../src/assets/css/styles.css";
import Footer from "./components/partials/Footer";
import AutoLogout from "./utilities/AutoLogOut";
import { HashRouter, json } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnimatePresence } from "framer-motion";
import { RoutesWithAnimation } from "./AnimatedRoute";
import { IPAPIkey } from "./environment";
import Drawer from "./components/Drawer";
import axios from "axios";
import { httpClientJava } from "./environment";
import { fetchSavingHistory, setSavingsHistory } from "./reducers/medication";
import { setIP } from "./reducers/global";
import { setAuth } from "./reducers/global";
import Loader from "./components/Loader";
import { useGetUser } from "./utilities/useGetUser";



function App() {
  // const [auth, setAuth] = useState("");
  // const [ip, setIp] = useState(null);
  // const [showMap, setShowMap] = useState(false);
  const [ownPosition, setOwnPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [savingsToRender, setSavingToRender] = useState([]);

  // const [totalSavings, setTotalSavings] = useState(0);



  const sessionLatitude = window.sessionStorage.getItem("latitude");
  const sessionLongitude = window.sessionStorage.getItem("longitude");
  const sessionPostal = window.sessionStorage.getItem("postalCode");

  // const userDetail = JSON.parse(localStorage.getItem("user"));
  const userDetail = useGetUser();

  // const savings = useSelector((state) => state.medication.savingHistory);
  // const auth = useSelector((state) => state.global.auth);
  const totalSavings =  userDetail?.totalSavings || 0;

  const ip = useSelector((state) => state.global.ip);
  const animatedLoading = useSelector((state) => state.global.animatedLoading);
  const isUpdatingPharmacy = useSelector((state) => state.global.isUpdatingPharmacy);


  const setAuth = (auth)=> {
    dispatch(setAuth(auth))
  } 


  const dispatch = useDispatch();

  const setIp = (ip) => {
    dispatch(setIP(ip));
  }


  useEffect(() => {
    if (userDetail?.proxyIpAddress)
      setIp(userDetail.proxyIpAddress)
  }, [userDetail?.proxyIpAddress])

  const getZipCode = async () => {
    if (sessionPostal == null) {
      // setLoading(true);
      await httpClientJava.post("/GetIpInfo", {
        ipAddress: ip
      }).then((response) => {
        const res = JSON.parse(response.data.data);
        // setLoading(false);
        setOwnPosition({
          ip: ip,
          lat: res.latitude,
          lng: res.longitude,
          showMap: true,
          postalCode: res.postal,
          responseZIP: res
        });
      }).catch((err) => {
        // setLoading(false)
        console.error(err);
        setOwnPosition({
          ip: ip,
          lat: 33.753746,
          lng: -84.386330,
          showMap: true,
          postalCode: 30301,
          responseZIP: {}
        });
      })
    }
    else {
      setOwnPosition({
        ip: ip,
        lat: sessionLatitude,
        lng: sessionLongitude,
        showMap: true,
        postalCode: sessionPostal,
        responseZIP: {}
      });
    }
  }

  useEffect(() => {
    if (ip != null && ip != "") {
      getZipCode();
    }
  }, [ip])


  // useEffect(() => {
  //   const getHistory = () => {
  //     dispatch(fetchSavingHistory(userDetail?.appUserSeqNo))
  //       .unwrap()
  //       .then((res) => {
  //         console.log({ responseSaving: res })
  //         dispatch(setSavingsHistory(res.data))
  //       })
  //       .catch((er) => {
  //         console.log(er)
  //       })
  //       .finally(e => {
  //       })
  //   }
  //   if (userDetail) {
  //     getHistory();
  //   }
  // }, [auth])


  // useEffect(() => {
  //   console.log({ savings });
  //   let savingsData = [];

  //   if (savings?.status == "OK" && savings?.data?.length > 0) {
  //     const filled = savings.data.filter((item) => item.claim_Status == "filled");

  //     filled.map((saving, index) => {

  //       if (index == 0) {
  //         let model = {
  //           id: index,
  //           drugsName: saving.drugName,
  //           dateFilled: saving.date_filled,
  //           price: saving.copay_amount
  //         }
  //         savingsData.push(model);
  //       }

  //       else {
  //         let checkDate = savingsData.filter((el, i) => el.dateFilled == saving.date_filled);
  //         if (checkDate.length > 0) {
  //           savingsData[checkDate[0].id].drugsName = savingsData[checkDate[0].id].drugsName + ", " + saving.drugName;
  //           savingsData[checkDate[0].id].price = savingsData[checkDate[0].id].price + saving.copay_amount;
  //         }
  //         else {
  //           let model = {
  //             id: index,
  //             drugsName: saving.drugName,
  //             dateFilled: saving.date_filled,
  //             price: saving.copay_amount
  //           }
  //           savingsData.push(model);
  //         }
  //       }
  //     });
  //     setSavingToRender(savingsData);
  //   }
  // }, [savings])

  // useEffect(() => {
    
  //   if (!savings || savings.status !== "OK" || !savings.data || savings.data.length === 0) {
  //     setSavingToRender([])
  //     return;
  //   }
  
  //   const filledSavings = savings.data;
  
  //   if (filledSavings.length === 0) {
  //     setSavingToRender([])
  //     return;
  //   }
  
  //   const savingsData = filledSavings.reduce((accumulator, saving, index) => {
  //     const existingIndex = accumulator.findIndex(el => el.dateFilled === saving.dateFilled);
  
  //     if (existingIndex !== -1) {
  //       // Update existing entry.
  //       accumulator[existingIndex].drugsName += `, ${saving.drugName}`;
  //       accumulator[existingIndex].price += saving.copayAmount;
  //     } else {
  //       // Create a new entry.
  //       accumulator.push({
  //         id: index,
  //         drugsName: saving.drugName,
  //         dateFilled: saving.dateFilled,
  //         price: saving.copayAmount,
  //       });
  //     }
  
  //     return accumulator;
  //   }, []);
  
  //   setSavingToRender(savingsData);
  // }, [savings]);

  // useEffect(() => {
  //   console.log({ savingsToRender })
  //   if (savingsToRender.length > 0 && totalSavings == 0) {

  //     savingsToRender.map((el, i) => {
  //       setTotalSavings(prev => prev + el.price)
  //     })
  //   }
  //   if(savingsToRender.length == 0){
  //     setTotalSavings(0)
  //   }
  // }, [savingsToRender])

  return (
    <>
      {(animatedLoading || isUpdatingPharmacy) && <Loader/> }
      <div className="appContainer">
        <div className="heading">
        </div>
        <div className="">
          <HashRouter>
            <ToastContainer/>
            <AutoLogout>
              <LocationProvider>
                <Drawer totalSavings={totalSavings} />
                <RoutesWithAnimation ownPosition={ownPosition} setOwnPosition={setOwnPosition} setIp={setIp} />
              </LocationProvider>
            </AutoLogout>
          </HashRouter>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default App;

const LocationProvider = ({ children }) => {
  return <AnimatePresence >{children}</AnimatePresence>;
}
