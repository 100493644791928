import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  ip: '',
  ownPosition: null,
  rxSenseToken : null,
  auth: null,
  insuranceInfo: null,
  isNewUser: null,
  isCalledAlready: false,
  hasDob: false,
  hasFirstName: false,
  resetModalIsOpen: false, 
  isUpdatingPharmacy: false,
  isRemovingPharmacy: false,
  animatedLoading: false,
  selectedDrug: undefined,
};

export const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setAnimatedLoading: (state, action) => {
      state.animatedLoading = action.payload;
    },
    setResetModalIsOpen: (state, action) => {
      state.resetModalIsOpen = action.payload;
    },
    setIP: (state, action) => {
      state.ip = action.payload;
    },
    setOwnPosition: (state, action) => {
      state.ownPosition = action.payload;
    },
    setRxSenseToken: (state, action) => {
      state.rxSenseToken = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setInsuranceInfo: (state, action) => {
      state.insuranceInfo = action.payload;
    },
    setNewUser: (state, action) => {
      state.isNewUser = action.payload;
    },
    setIsCalledAlready: (state, action) => {
      state.isCalledAlready = action.payload;
    },
    setHasFirstName: (state, action) => {
      state.hasFirstName = action.payload
    },
    setHasDob: (state, action) => {
      state.hasDob = action.payload
    },
    setIsUpdatingPhamracy: (state, action) => {
      state.isUpdatingPharmacy = action.payload
    },
    setIsRemovingPharmacy: (state, action) => {
      state.isRemovingPharmacy = action.payload
    },
    setDrugSelected: (state, action) => {
      state.selectedDrug = action.payload
    },
  }
});

// Action creators are generated for each case reducer function
export const { setLoader, setIP, setOwnPosition, setRxSenseToken, setAuth, setInsuranceInfo, setNewUser, setIsCalledAlready, setHasFirstName, setHasDob, setResetModalIsOpen, setIsUpdatingPhamracy, setAnimatedLoading, setDrugSelected, setIsRemovingPharmacy } = global.actions;

export default global.reducer;