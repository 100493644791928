import Modal from "react-bootstrap/Modal";
import { MotionButton, MotionIcon } from "../../motion/MotionButton";
import InputMask from "react-input-mask";
import { motion, AnimatePresence } from "framer-motion";
import PaperPlane from "../../../assets/images/paper-plane.svg";
import PaperPlaneM from "../../../assets/images/paper-plane-m.svg";
import { useStyleMediaQuery } from "../hooks";
import { useEffect, useRef } from "react";
import cancel from "./../../../assets/images/RxLink assets/new assets/regular x.svg"
import TickCross from "../../../utilities/TickCross";
import cross from "../../../assets/images/cross.png";
import tick from "../../../assets/images/check.png";
import ariaAnnounce from "../../../utilities/useAnnounceText";


export const SendSMSOrEmailModalTab = ({ isOpen, formik, handleClose, isSMS, sendEmail, sendSMS, validateEmail, emailError, hasSuccess, termsHandler, termsError, isValidEmailModal, isEmail }) => {
    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 500 });

    const { isSubmitting, isValidating } = formik;
    const handleInputFocus = () => {
        if (isSMS) {
            if (Object.keys(formik.errors).length > 0 || termsError) {
                const firstErrorField = Object.keys(formik.errors).find(
                    (fieldName) => formik.touched[fieldName]
                );
                if (firstErrorField) {
                    if (firstErrorField === 'username') {
                        window.document.getElementById("smsPhoneInputTab").focus();
                        ariaAnnounce(formik.errors.username, "errorAnnounce");
                    }
                }
                else if (termsError) {
                    window.document.getElementById("termsCheckBoxMobile").focus();
                    announceErrorMessage('termError');
                }
            }
        }
        else {
            if (emailError) {
                document.getElementById('sendEmailInputTab').focus();
                // announceErrorMessage('emailError', emailError);
                ariaAnnounce(emailError, "errorAnnounce");

            }
            else if (termsError) {
                window.document.getElementById("termsCheckBoxMobile").focus();
                announceErrorMessage('termError');
            }
        }
    }

    const text = 'Personalized medication savings';
    useEffect(() => {
        console.log({ isSubmitting, isValidating })
        if (isSubmitting) {
            if (Object.keys(formik.errors).length > 0
                || termsError
            ) {
                handleInputFocus();
            }
        }
    }, [isSubmitting, isValidating]);

    const announceErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        console.log({ message })
        if (liveRegion) {
            if (message)
                liveRegion.innerText = message;
            liveRegion.setAttribute('aria-live', 'assertive');
            // debugger
        }
    };



    const hideErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        if (liveRegion) {
            // liveRegion.innerText = 'Password must be 8 characters long and contain a mix of letters and numbers.';
            liveRegion.setAttribute('aria-live', 'off');
        }
    };

    useEffect(() => {
        if (hasSuccess) {
            ariaAnnounce("Coupon Sent", "sentCouponId");
        }
    }, [hasSuccess])

    useEffect(() => {
        if (isOpen && !hasSuccess)
            document.getElementById("popHeadline").focus();
        // ariaAnnounce("Send a coupon via " + (isSMS ? "SMS" : "e-Mail"), "errorAnnounce")
    }, [isOpen])
    return (
        <Modal show={isOpen} onHide={handleClose} keyboard={false} centered>
            <Modal.Body className="coupon_popup_tab">
                <div className="row x">
                    <button
                        className="crossButton"
                        onClick={handleClose}
                    >
                        <img src={cancel} alt="close popup" />
                    </button>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 autoMargin" id="errorAnnounce">
                    <div className="paper-plane-image  mt-4 ">
                        <motion.img
                            key={isMobile ? PaperPlaneM : PaperPlane}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            src={isMobile ? PaperPlaneM : PaperPlane}
                            transition={{ duration: 1, ease: 'easeIn' }}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                </div>
                {hasSuccess ?
                    <>
                        <section className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 p-0 inner">
                            <div className="container">
                                <form
                                    id="popupSendSMSEmail"
                                    className=" "
                                >
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 pt-3">
                                        <h1 id="sentCouponId" aria-live="off" style={{ fontWeight: '700', fontSize: 'calc(0.975rem + 1.5vw)' }}>Coupon Sent</h1>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <p style={{ fontSize: '0.9rem' }} id="sentCouponDescription" aria-live="off">
                                                Your personalized medication savings plan has
                                                been sent to your designated contact
                                            </p>
                                        </div>
                                        {
                                            !isMobile &&
                                            <div className="row mt-2 mb-4">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="">
                                                        {/* <MotionButton> */}
                                                        <button
                                                            type="button"
                                                            className="btn_default"
                                                            onClick={handleClose}
                                                        >
                                                            Close
                                                        </button>
                                                        {/* </MotionButton> */}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </section>
                        {
                            isMobile &&
                            <div className="row mt-2 mb-4">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="">
                                        {/* <MotionButton> */}
                                        <button
                                            type="button"
                                            className="btn_default"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                        {/* </MotionButton> */}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <>
                        <section className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 pt-1 inner">
                            <div className="container" >
                                <form
                                    onSubmit={formik.handleSubmit}
                                    id="loginID"
                                    className=" "
                                >
                                    {(isSMS || isEmail) && (
                                        <div role="alert" className="row mt-3" id="popHeadline" style={{outline: "none"}}>
                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                                                <h1 className="phone_popup_heading mt-2">
                                                    Send a coupon via {isSMS ? "SMS" : "e-Mail"}
                                                </h1>
                                            </div>
                                        </div>
                                    )}
                                    <div className="mt-3">
                                        <div className="hrDiv hrDivpadding"></div>
                                    </div>

                                    {
                                        isSMS ?
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 forget_input_eye" style={{ width: "95%" }}>
                                                    <InputMask
                                                        name="username"
                                                        className={
                                                            "form-control custom-form-control" +
                                                            (formik.errors.username && formik.touched.username
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        mask="(999) 999-9999"
                                                        maskChar={null}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.username}
                                                        placeholder="Enter your phone number"
                                                        type="tel"
                                                        id="smsPhoneInputTab"
                                                        aria-label="Mobile Phone Number"
                                                    // onFocus={() => setTimeout(() => {
                                                    //     announceErrorMessage('usernameError', formik.errors.username)
                                                    // }, [0])}
                                                    // onBlur={() => hideErrorMessage('usernameError')}
                                                    ></InputMask>
                                                    {formik.errors.username && formik.touched.username && <div className="invalid-feedback" role="alert" aria-live="off" id="usernameError">
                                                        {formik.errors.username && formik.touched.username
                                                            ? formik.errors.username
                                                            : null}
                                                    </div>}
                                                    {/* {formik.errors.username && formik.touched.username && (
                                                        <span className="badge bg-danger rounded-pill error-circle-sms">x</span>
                                                    )}
                                                    {!formik.errors.username && formik.touched.username && (
                                                        <span className="badge bg-success rounded-pill error-circle">✔</span>
                                                    )} */}
                                                    {TickCross(formik, "username", "error-circle-sms")}
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 forget_input_eye" style={{ width: "95%" }}>
                                                    <input
                                                        type="email"
                                                        className="form-control text_input_insurance"
                                                        placeholder="your@email.com"
                                                        onChange={(e) => validateEmail(e)}
                                                        id="sendEmailInputTab"
                                                        aria-label="e-Mail"
                                                        onFocus={() => setTimeout(() => {
                                                            announceErrorMessage('emailError', emailError)
                                                        }, [200])}
                                                        onBlur={() => hideErrorMessage('emailError')}
                                                    />
                                                    <span role="alert" className="emailError" id="emailError" aria-live="off">{emailError}</span>
                                                    {/* {TickCross(null, null, "error-circle-sms", emailError)} */}
                                                    {
                                                        emailError && <span className={"error-circle-sms"}><img src={cross} height={13} width={13} /></span>
                                                    }
                                                    {/* {
                                                        isValidEmailModal != "12" && isValidEmailModal && <span className={"error-circle-sms"}><img src={tick} height={13} width={13} /></span>
                                                    } */}
                                                </div>
                                            </div>
                                    }
                                    {
                                        !isMobile &&
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="mt-20">
                                                    {/* <MotionButton> */}
                                                    <button
                                                        className="btn_success"
                                                        onClick={isSMS ? sendSMS : sendEmail}>
                                                        Send coupon
                                                    </button>
                                                    {/* </MotionButton> */}
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="mt-20">
                                                    {/* <MotionButton> */}
                                                    <button
                                                        type="button"
                                                        className="btn_default"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                    {/* </MotionButton> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-2 mb-2">
                                        <div style={{ width: "5%" }}>
                                            {/* <MotionIcon> */}
                                            <input type="checkbox" aria-labelledby={isSMS ? "Enter SMS" : "Enter email"} name='terms'
                                                className="p-1 mr-2" onChange={(e) => termsHandler(e)}
                                                id="termsCheckBoxMobile" aria-label="terms"
                                                onFocus={() => setTimeout(() => {
                                                    announceErrorMessage('termError')
                                                }, [200])}
                                                onBlur={() => hideErrorMessage('termError')}
                                            />
                                            {/* </MotionIcon> */}
                                        </div>
                                        {(isSMS || isEmail) && (
                                            <div className="pl-0" style={{ width: "85%", marginTop: "5px", marginLeft: "5px", position: "relative" }}>
                                                {
                                                    termsError ?
                                                        <>
                                                            <p role="alert" className="phone_popup_error" id="termError" aria-live="off" style={{fontSize: 11}}>
                                                                I agree to receiving alerts related to personalized
                                                                medication savings and refill reminders. Standard
                                                                rates may apply.
                                                            </p>
                                                            <span className={"error-circle-sms"}><img src={cross} height={13} width={13} /></span>
                                                        </>
                                                        :
                                                        <>
                                                            <p role="alert" className="phone_popup" style={{fontSize: 11}}>
                                                                I agree to receiving alerts related to personalized
                                                                medication savings and refill reminders. Standard
                                                                rates may apply.
                                                            </p>
                                                        </>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </section>
                        {
                            isMobile &&
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="mt-20">
                                        {/* <MotionButton> */}
                                        <button
                                            className="btn_success"
                                            onClick={isSMS ? sendSMS : sendEmail}>
                                            Send coupon
                                        </button>
                                        {/* </MotionButton> */}
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="mt-20">
                                        {/* <MotionButton> */}
                                        <button
                                            type="button"
                                            className="btn_default"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                        {/* </MotionButton> */}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}


export const SendSMSOrEmailModalWeb = ({ isOpen, formik, handleClose, isSMS, sendEmail, sendSMS, validateEmail, emailError, hasSuccess, termsHandler, termsError, isValidEmailModal, isEmail }) => {
    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 900 });

    useEffect(() => {
        if (isMobile) {
            handleClose()
        }
    }, [isMobile])

    const announceErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        console.log({ message })
        if (liveRegion) {
            if (message)
                liveRegion.innerText = message;
            liveRegion.setAttribute('aria-live', 'assertive');
            // debugger
        }
    };



    const hideErrorMessage = (id, message) => {
        const liveRegion = document.getElementById(id);
        if (liveRegion) {
            // liveRegion.innerText = 'Password must be 8 characters long and contain a mix of letters and numbers.';
            liveRegion.setAttribute('aria-live', 'off');
        }
    };

    useEffect(() => {
        if (hasSuccess) {
            ariaAnnounce("Coupon Sent", "couponSentForm")
        }
    }, [hasSuccess])

    useEffect(() => {
        if (isOpen && !hasSuccess)
            document.getElementById("popupHeadline").focus();
        // ariaAnnounce("Send a coupon via " + (isSMS ? "SMS" : "e-Mail"), "sendSMSform")
    }, [isOpen, hasSuccess])


    const { isSubmitting, isValidating } = formik;
    const handleInputFocus = () => {
        if (isSMS) {
            if (Object.keys(formik.errors).length > 0 || termsError) {
                const firstErrorField = Object.keys(formik.errors).find(
                    (fieldName) => formik.touched[fieldName]
                );
                if (firstErrorField) {
                    if (firstErrorField === 'username') {
                        window.document.getElementById("smsPhoneInput").focus();
                        ariaAnnounce(formik.errors.username, "sendSMSform");
                    }
                }
                else if (termsError) {
                    window.document.getElementById("termsCheckBoxWeb").focus();
                    announceErrorMessage('termError');
                }
            }
        }
        else {
            if (emailError) {
                document.getElementById('sendEmailInput').focus();
                // announceErrorMessage('emailError', emailError);
                ariaAnnounce(emailError, "sendSMSform");

            }
            else if (termsError) {
                window.document.getElementById("termsCheckBoxWeb").focus();
                announceErrorMessage('termError');
            }
        }
    }

    const text = 'Personalized medication savings';
    useEffect(() => {
        console.log({ isSubmitting, isValidating })
        if (isSubmitting) {
            if (Object.keys(formik.errors).length > 0
                || termsError
            ) {
                handleInputFocus();
            }
        }
    }, [isSubmitting, isValidating]);

    return (
        <Modal show={isOpen} onHide={handleClose} keyboard={false} centered>
            <Modal.Body className="coupon_popup">
                <div className="row x">
                    <button
                        className="crossButton"
                        onClick={handleClose}
                    >
                        <img src={cancel} alt="close popup" />
                    </button>
                </div>
                <div className="row c_row">
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5 p-0 autoMargin" id="errorWeb">
                        <div className="paper-plane-image  mt-4 ">
                            <motion.img
                                key={isMobile ? PaperPlaneM : PaperPlane}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                src={isMobile ? PaperPlaneM : PaperPlane}
                                transition={{ duration: 1, ease: 'easeIn' }}
                                className="img-fluid"
                                alt=""
                            />
                        </div>
                    </div>
                    {hasSuccess ?
                        <section className="col-7 col-sm-7 col-md-7 col-lg-7 p-0 autoMargin inner">
                            <div tabIndex="0" role="alert" className="container">
                                <form
                                    id="couponSentForm"
                                    className=" "
                                >
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                        <h1 style={{ fontWeight: '700', fontSize: 'calc(0.975rem + 1.5vw)' }}>Coupon Sent</h1>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <p style={{ fontSize: '0.9rem' }}>
                                                Your personalized medication savings plan has
                                                been sent to your designated contact
                                            </p>
                                        </div>
                                        <div className="row mt-2 mb-4">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="">
                                                    {/* <MotionButton> */}
                                                    <button
                                                        type="button"
                                                        className="btn_default"
                                                        onClick={handleClose}
                                                    >
                                                        Close
                                                    </button>
                                                    {/* </MotionButton> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </section>
                        :
                        <section className="col-7 col-sm-7 col-md-7 col-lg-7 p-0 autoMargin inner" >
                            <div className="container">
                                <form
                                    onSubmit={formik.handleSubmit}
                                    id="sendSMSform"
                                    className=" "
                                >
                                    {(isSMS || isEmail) && (
                                        <div role="alert" className="row mt-3" tabIndex={0} id="popupHeadline" style={{outline: "none"}}>
                                            <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                                                <h1 className="phone_popup_heading mt-2">
                                                    Send a coupon via {isSMS ? "SMS" : "e-Mail"}
                                                </h1>
                                            </div>
                                        </div>
                                    )}

                                    <div className="mt-3">
                                        <div className="hrDiv hrDivpadding"></div>
                                    </div>

                                    {
                                        isSMS ?
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 forget_input_eye" >
                                                    <InputMask
                                                        name="username"
                                                        className={
                                                            "form-control custom-form-control" +
                                                            (formik.errors.username && formik.touched.username
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        mask="(999) 999-9999"
                                                        maskChar={null}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.username}
                                                        placeholder="Enter your phone number"
                                                        type="tel"
                                                        id="smsPhoneInput"
                                                        aria-label="Mobile Phone Number"
                                                        onFocus={() => setTimeout(() => {
                                                            // announceErrorMessage('usernameError', formik.errors.username)
                                                            formik.errors.username && formik.touched.username && ariaAnnounce(formik.errors.username, "sendSMSform")
                                                        }, [200])}
                                                    // onBlur={() => hideErrorMessage('usernameError')}
                                                    ></InputMask>
                                                    {formik.errors.username && formik.touched.username && <div className="invalid-feedback" id="usernameError" aria-live="off">
                                                        {formik.errors.username && formik.touched.username
                                                            ? formik.errors.username
                                                            : null}
                                                    </div>}
                                                    {TickCross(formik, "username", "error-circle-sms")}
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 forget_input_eye">
                                                    <input
                                                        type="email"
                                                        className="form-control text_input_insurance"
                                                        placeholder="your@email.com"
                                                        onChange={(e) => validateEmail(e)}
                                                        id="sendEmailInput"
                                                        aria-label="e-Mail"
                                                        onFocus={() => setTimeout(() => {
                                                            announceErrorMessage('emailError', emailError);
                                                            emailError && ariaAnnounce(emailError, "sendSMSform")
                                                        }, [200])}
                                                    // onBlur={() => hideErrorMessage('emailError')}
                                                    />
                                                    <span role="alert" className="emailError" aria-live="off" id="emailError">{emailError}</span>
                                                    {
                                                        emailError && <span className={"error-circle-sms"}><img src={cross} height={13} width={13} /></span>
                                                    }
                                                    {/* {
                                                        isValidEmailModal != "12" && isValidEmailModal && <span className={"error-circle-sms"}><img src={tick} height={13} width={13} /></span>
                                                    } */}
                                                </div>
                                            </div>
                                    }
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="mt-20">
                                                {/* <MotionButton> */}
                                                <button
                                                    className="btn_success"
                                                    onClick={isSMS ? sendSMS : sendEmail}>
                                                    Send coupon
                                                </button>
                                                {/* </MotionButton> */}
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="mt-20">
                                                {/* <MotionButton> */}
                                                <button
                                                    type="button"
                                                    className="btn_default"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </button>
                                                {/* </MotionButton> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div style={{ width: "5%" }} className="forget_input_eye">
                                            {/* {termsError && (
                                                <span className="badge bg-danger rounded-pill error-circle-checkbox">x</span>
                                            )} */}
                                            {/* <MotionIcon> */}
                                            <input
                                                type="checkbox"
                                                name='terms'
                                                className="p-1"
                                                onChange={(e) => termsHandler(e)}
                                                id="termsCheckBoxWeb"
                                                aria-label="terms"
                                                aria-describedby="termError"
                                                onFocus={() => setTimeout(() => {
                                                    announceErrorMessage('termError')
                                                }, [200])}
                                                onBlur={() => hideErrorMessage('termError')}
                                            />
                                            {/* </MotionIcon> */}
                                        </div>
                                        {(isSMS || isEmail) && (
                                            <div className="pl-0" style={{ width: "93%", marginTop: "5px", marginLeft: "5px", position: "relative" }}>
                                                {
                                                    termsError ?
                                                        <>
                                                            <p role="alert" className="phone_popup_error" id="termError" style={{fontSize: 11}}>
                                                                I agree to receiving alerts related to personalized
                                                                medication savings and refill reminders. I also agree to the <a href="https://rxlink.com/terms/" target="_blank">Terms of Service</a>. Standard
                                                                rates may apply.
                                                            </p>
                                                            <span className={"error-circle-sms"}><img src={cross} height={13} width={13} /></span>
                                                        </>

                                                        :
                                                        <p role="alert" className="phone_popup" style={{fontSize: 11}}>
                                                            I agree to receiving alerts related to personalized
                                                            medication savings and refill reminders. I also agree to the <a href="https://rxlink.com/terms/" target="_blank">Terms of Service</a>. Standard
                                                            rates may apply.
                                                        </p>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </section>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}