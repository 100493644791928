import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { saveInsurance } from "../../reducers/submitInsurance";
import "react-toastify/dist/ReactToastify.css";
import Header from "../partials/Header";

import PaperPlane from "../../assets/images/paper-plane.svg";
import PaperPlaneM from "../../assets/images/paper-plane-m.svg";
import Group831 from "../../assets/images/Group831.png";
import { MotionButton, MotionIcon } from "../motion/MotionButton";
import { useStyleMediaQuery } from "./hooks";

export default (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var userDetail = JSON.parse(localStorage.getItem("user"));

  const coupon = () => {
    navigate("/medications");
  };

  const FindPharmacy = () => {
    navigate("/findPharmacy");
  };

  const validationSchema = Yup.object().shape({
    bin: Yup.string().required("Bin is required"),
    // username: Yup.string()
    //     .required('Username is required')
    //     .min(6, 'Username must be at least 6 characters')
    //     .max(20, 'Username must not exceed 20 characters'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),
    pcn: Yup.string().required("PCN is required"),
    groupNumber: Yup.string().required("Group number is required"),
    memberId: Yup.string().required("Installment Id is required"),
    // confirmPassword: Yup.string()
    //     .required('Confirm Password is required')
    //     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });

  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 767 });

  const formik = useFormik({
    initialValues: {
      bin: "",
      pcn: "",
      groupNumber: "",
      memberId: "",
    },
    validationSchema,
    // onSubmit: (data) => {
    //   let body = {
    //     bin: data?.bin,
    //     pcn: data?.pcn,
    //     groupNumber: data?.groupNumber,
    //     memberId: data?.memberId,
    //     appUserSeqNo: userDetail?.appUserSeqNo,
    //   };
    //   dispatch(saveInsurance(body))
    //     .unwrap()
    //     .then((response) => {
    //       if (response?.data) {
    //         toast.success("Insurance has been added successfully.", {
    //           position: toast.POSITION.BOTTOM_CENTER
    //       });
    //         navigate("/medications");
    //       } else if (response?.message != "OK") {
    //         toast.error(response?.message, {
    //           position: toast.POSITION.BOTTOM_CENTER
    //       });
    //       }
    //     })
    //     .catch((e) => {
    //   //console.log(e);
    //     });
    // },
  });

  return (
    <>
      <AnimatePresence mode="wait">
        <section className="fadeAni">
          <div className="row g-0">
          <a href='javascript:void(0)' onClick={() => coupon()}>
            <div className="medicine_icon pharma_icon">
              <MotionIcon>
                <i className="fas fa-angle-left" alt="Back" title="Go Back"></i>
              </MotionIcon>
            </div>
            </a>
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="">&nbsp;</div>
            </div>
          </div>
          <div className="margintop10">
            <div className="row g-0">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0 autoMargin">
                <div className="paper-plane-image">
                  <motion.img
                    key={isMobile ? PaperPlaneM : PaperPlane}
                    // src={image.src}
                    initial={{ opacity: 0, x: 500 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0 }}
                    src={isMobile ? PaperPlaneM : PaperPlane}
                    transition={{ duration: 0.8, type: 'tween' }}
                    className="img-fluid"
                    alt=""
                  />

                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-6 paddingZero autoMargin"
                style={{ zIndex: 100 }}
              >
                <div className="">
                  {/* <motion.div
                    className="insurance_inner_main"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                  > */}
                    <div
                    className="insurance_inner_main"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                  ></div>
                    <form onSubmit={formik.handleSubmit} id="loginID">
                      <div className="row" id="cc-info-div">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          {/* <motion.div
                            className="insurance_inner"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.7 }}
                          > */}
                           <div
                            className="insurance_inner"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.7 }}
                          >
                            {/* <motion.div
                              className="row"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.8 }}
                            > */}
                             <div
                              className="row"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.8 }}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h1 role="alert">Coupon Sent</h1>
                              </div>
                              </div>
                              {/* </motion.div> */}
                            {/* <motion.div
                              className="row"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.8, delay: 0.15 }}
                            > */}
                             <div
                              className="row"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.8, delay: 0.15 }}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <p>
                                  Your personalized medication savings plan has
                                  been sent to your designated contact
                                </p>
                                <hr></hr>
                                <b>
                                  Want to view your aggregate savings and be
                                  alerted when a lower price appears?
                                </b>
                              </div>
                              {/* <motion.div
                                className="row"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.8 }}
                              > */}
                                <div
                                className="row"
                                initial={{ x: 300 }}
                                animate={{ x: 0 }}
                                transition={{ duration: 0.8 }}
                              >
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="">
                                      <input type="checkbox" className="p-2" />
                                      <span className="p-2 coupon_sent_span">
                                        I agree receiving alerts related to
                                        personalized medication savings and
                                        refill reminders
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                {/* </motion.div> */}
                              </div>
                              {/* </motion.div> */}
                            {/* <motion.div
                              className="button_div insurance_inner_main_btn mt-4 g-0"
                              id="cc-info-div-smbt"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.7 }}
                            > */}
                             <div
                              className="button_div insurance_inner_main_btn mt-4 g-0"
                              id="cc-info-div-smbt"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.7 }}
                            >
                              <div className="margin-right webView">
                                {/* <MotionButton> */}
                                  <button
                                    className="btn_success NIbutton"
                                    type="submit"
                                  // onClick={() => submit()}
                                  >
                                    Create an account
                                  </button>
                                {/* </MotionButton> */}
                              </div>

                              <div className="margin-right webView">
                                {/* <MotionButton> */}
                                  <button
                                    className="btn_default NIbutton "
                                    type="button"
                                  // onClick={() => CopayProgram()}
                                  >
                                    Login
                                  </button>
                                {/* </MotionButton> */}
                              </div>
                              </div>
                              {/* </motion.div> */}
                            </div>
                            {/* </motion.div> */}
                          {/* <motion.div
                            className="button_div insurance_inner_main_btn2  g-0"
                            id="cc-info-div-smbt"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.7 }}
                          > */}
                            <div
                            className="button_div insurance_inner_main_btn2  g-0"
                            id="cc-info-div-smbt"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.7 }}
                          ></div>
                            <div className="margin-right webView">
                              {/* <MotionButton> */}
                                <button
                                  className="btn_success NIbutton"
                                  type="submit"
                                // onClick={() => submit()}
                                >
                                  Create an account
                                </button>
                              {/* </MotionButton> */}
                            </div>
                            <div className="margin-right webView">
                              {/* <MotionButton> */}
                                <button
                                  className="btn_default NIbutton"
                                  type="button"
                                // onClick={() => CopayProgram()}
                                >
                                  Login
                                </button>
                              {/* </MotionButton> */}
                            </div>
                            {/* for mobile View */}
                            <div className="row mobileView">
                              <div className="col-6">
                                {/* <MotionButton> */}
                                  <button
                                    className="btn_success "
                                    type="button"
                                  // onClick={() => CopayProgram()}
                                  >
                                    Create an account
                                  </button>
                                {/* </MotionButton> */}
                              </div>
                              <div className="col-6">
                                {/* <MotionButton> */}
                                  <button
                                    type="button"
                                    className="btn_default "
                                  // onClick={() => CopayProgram()}
                                  >
                                    Login
                                  </button>
                                {/* </MotionButton> */}
                              </div>
                            </div>
                          {/* </motion.div> */}
                        </div>
                      </div>
                    </form>
                  {/* </motion.div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="mobileView">
            <div className="row g-0">
              <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
                <div className="dot-main">
                  <span className="dot"></span>
                  <span className="dot dotActive"></span>
                  <span className="dot"></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimatePresence>
    </>
  );
};
